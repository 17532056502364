import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call, func_privileges} from '../../../../../libs/utils';
import './css/style.css';
import PubSub from 'pubsub-js';
import {confirm} from 'react-bootstrap-confirmation';
import config from '../../../../../config'



export default class Reservation extends Component {
    constructor(props) {
        let func = { 'edit': 144, 'get': 143}
        func = func_privileges(func)
        super(props);
        this.state = {
            "func": func,
            name: "预约信息",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            exportYuyue:{},
            column: [
                {
                    "name": "ID",
                    "selector": row => row.id,
                    "width": "100px",
                },
                {
                    "name": "预约人姓名",
                    "selector": row => row.xm
                },
                {
                    "name": "预约人电话",
                    "selector": row => row.dh
                },
                {
                    "name": "居住地址",
                    "selector": row => row.jzdz
                },
                {
                    "name": "处理状态",
                    "selector": row => {
                            if (row.clzt === 0){
                                return <span style={{"minWidth": "150px"}}>
                                    <FontAwesomeIcon title='处理' icon="fa fa-solid fa-play"
                                                    size='xl'
                                                    style={{
                                                        'color': 'red',
                                                        "cursor": "pointer"
                                                    }} onClick={e => {
                                                        console.log(row);
                                                        this.edit_sx(row.id)
                                                    }}/>
                                </span>
                            }
                            return <span style={{"minWidth": "150px"}}>
                                    <FontAwesomeIcon title='已处理' icon="fa fa-solid fa-check"
                                                    size='xl'
                                                    style={{
                                                        'color': 'green',
                                                        "cursor": "pointer"
                                                    }}
                                    />
                                </span>

                    }
                },
                {
                    "name": "提交时间",
                    "selector": row => row.tjsj
                },
                {
                    "name": "处理人",
                    "selector": row => row.clr
                },
                {
                    "name": "处理时间",
                    // "selector": row => row.clsj
                    "selector": row => row.operationtime
                },

            ],
            "search_rule": [
                {
                    "label_name": "姓名",
                    "type": "text",
                    "id": "search_xm",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                {
                    "label_name": "电话",
                    "type": "text",
                    "id": "search_dh",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                {
                    "label_name": "居住地址",
                    "type": "text",
                    "id": "search_jzdz",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                {
                    "label_name": "处理状态",
                    "type": "select",
                    "id": "search_clzt",
                    "value": "",
                    "must": false,
                    "nullable": false,
                    "field": 'value',
                    "optionData":[{"value": "", "label": "请选择"}, {"value": 0, "label": "未处理"}, {"value": 1, "label": "已处理"}]
                },
                {
                    "label_name": "提交开始时间",
                    "type": "datetime-local",
                    "id": "search_tjkssj",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                
                {
                    "label_name": "结束时间",
                    "type": "datetime-local",
                    "id": "search_tjjssj",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
/*                 {
                    // "label_name": "结束时间",
                    // "type": "datetime-local",
                    // "id": "search_tjjssj",
                    // "value": "",
                    // "must": false,
                    // "nullable": false,
                    'callback':function() {
                        return <div className='form-group form-floating-label col-md-3 text-right' style={{"marginTop": '5px'}}>
                            <button className='col-md-3 btn btn-info btn-sm' style={{display: 'inline-block'}} onClick={()=>{
                                this.exportYuyue()
                            }}>导出
                            </button>
                        </div>
                    }
                }, */
            ],
            selectRows: {},
        }
    }

    componentDidMount() {
        this.get_all_sx()
    }

    
    get_all_sx = () => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/reservation', 'GET').then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }
    
    edit_sx = async (id) => {
        console.log(id);
        const result = await confirm(<h2 className='text-center text-danger'>确认处理?</h2>,
        {
            "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
            "okText": "确认",
            okButtonStyle: 'warning',
            "cancelText": "取消"
        });
        if (result) {
            http_call('/jzzwdt/reservation/change_status', 'POST', {'id': id,'clzt': 1 }).then(res => {
                let alert_info;
                if (res.RTN_CODE === '01') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    this.setState({"modal_show": false})
                    this.get_all_sx()
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }
    
    // handleTimeFomatter(datatime){
    //     // 2023-05-12T15:05  ----> 2023-05-12 12:34:56   2023-05-12+16%3A33%3A00
    //     datatime = datatime.replace("T"," ")
    //     return  datatime + ':00'
    // }
    inquiry_sx = (data) => {
        let DATA = {}
        // console.log(data);
        // PubSub.publish('Loding', true);
        Object.keys(data).forEach((key)=>{
            DATA[key.replace('search_', '')] = data[key];
        })
        // console.log(DATA);
        // console.log(DATA.tjkssj);
        // DATA.tjkssj = this.handleTimeFomatter(DATA.tjkssj)
        // DATA.tjjssj = this.handleTimeFomatter(DATA.tjjssj)
        if (DATA.tjkssj !== undefined ) {
            DATA.tjkssj = DATA.tjkssj.replace("T"," ")+":00";
        }
        if (DATA.tjjssj !== undefined ) {
            DATA.tjjssj = DATA.tjjssj.replace("T"," ")+":00";
        }
        if (DATA.clzt !== undefined) {
            DATA.clzt = DATA.clzt.value;
        }
        this.setState({'exportYuyue': DATA})
        http_call('/jzzwdt/reservation', 'GET', DATA).then(res => {
            this.setState({"tableData": res.DATA})
        })
    }
    exportYuyue = ()=>{
        // console.log(this.state.exportYuyue);
        let Authorization = localStorage.getItem("accessToken")
        let listStr = Object.entries(this.state.exportYuyue)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
        // console.log(listStr==='');
        if (listStr==='') {
            fetch(config.api_url +'/jzzwdt/reservation?download_excel=1', {headers: {"Authorization": Authorization}})
            .then(response => {
                if (response.ok) {
                    // 提取文件名
                    // console.log(response)
                    const filename = response.headers.get('content-disposition').split('filename=')[1];
                    // 返回文件内容的 Blob 对象
                    return response.blob().then(blob => ({filename, blob}));
                } else {
                    throw new Error('文件下载失败');
                }
            })
            .then(({filename, blob}) => {
                // 创建一个下载链接
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename;
    
                // 模拟点击下载链接
                downloadLink.click();
            })
            .catch(error => {
                console.error('文件下载错误:', error);
            });
        } else{
            fetch(config.api_url +'/jzzwdt/reservation?download_excel=1&'+listStr, {headers: {"Authorization": Authorization}})
            .then(response => {
                if (response.ok) {
                    // 提取文件名
                    // console.log(response)
                    const filename = response.headers.get('content-disposition').split('filename=')[1];
                    // 返回文件内容的 Blob 对象
                    return response.blob().then(blob => ({filename, blob}));
                } else {
                    throw new Error('文件下载失败');
                }
            })
            .then(({filename, blob}) => {
                // 创建一个下载链接
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename;
    
                // 模拟点击下载链接
                downloadLink.click();
            })
            .catch(error => {
                console.error('文件下载错误:', error);
            });
        }

    }

    render() {
        return (
            <div className='page-inner'>
                <div className="page-header">
                    <h4 className="page-title">{this.state.name}</h4>
                    <ul className="breadcrumbs">
                        <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>{this.state.name}</span>
                        </li>
                    </ul>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                <TableSearchFields 
                                    rule={this.state.search_rule}
                                    onSearch={(data) => {this.inquiry_sx(data)}}
                                    other_op={()=>{
                                        // window.Location.href = ''
                                        return  <button href className='btn btn-info btn-sm' onClick={e=>{
                                            this.exportYuyue(e)
                                        }}>导出
                                        </button>
                                    }}
                                    />
                            </div>
                            <DataTable
                                noDataComponent={<h1
                                style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>}
                            columns={this.state.column}
                            data={this.state.tableData}
                            selectableRows
                            progressPending={this.state.pending}
                            pagination
                            paginationComponentOptions = {{
                                rowsPerPageText:'每页行数:',
                                rangeSeparatorText: '/',
                                selectAllRowsItem: true,
                                selectAllRowsItemText: '全部'
                            }}
                            fixedHeader
                            fixedHeaderScrollHeight={"100vh"}
                            theme=""
                            onSelectedRowsChange={(rows) => {
                            this.setState({"selectRows": rows})
                        }}
                            />
                        </div>
                    </div>
                </div>
                {this.state.modal_show ?
                    <DynamicModal show={this.state.modal_show}
                                  rules={this.state.modal_rules}
                                  title={this.state.modal_title}
                                  submit_title={this.state.modal_submit_title}
                                  close_callback={e => {
                                      this.setState({"modal_show": false})
                                  }}
                                  submit_callback={this.state.modal_submit_callback}
                    />
                    : null
                }
            </div>
        );
    }
}

