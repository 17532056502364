import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call, func_privileges} from '../../../../../libs/utils';
import './css/style.css';
import PubSub from 'pubsub-js';
import {confirm} from 'react-bootstrap-confirmation';


export default class SubItems extends Component {
    constructor(props) {
        let func = {"add": 155, 'del': 156, 'edit': 154, 'get': 153}
        func = func_privileges(func)
        super(props);
        this.state = {
            "func": func,
            name: "办理项管理",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            column: [
                {
                    "name": "ID",
                    "selector": row => row.id,
                    "width":"100px"
                },
                {
                    "name": "事项类型",
                    "selector": row => row.sxlxmc
                },
                {
                    "name": "事项",
                    "selector": row => row.sxmc
                },
                {
                    "name": "办理项名称",
                    "selector": row => row.blxmc
                },
                {
                    "name": "行使层级",
                    "selector": row => row.xscj
                },
                {
                    "name": "备注",
                    "selector": row => row.bz
                },
                {
                    "name": "操作",
                    "width": "100px",
                    "selector": row => {
                        return <div style={{"minWidth": "150px"}}>
                            { this.state.func.del ?<FontAwesomeIcon title='删除' icon="fa fa-trash"
                                             size='xl'
                                             style={{
                                                 'color': 'red',
                                                 "cursor": "pointer"
                                             }} onClick={e => {
                                this.del_sx(row.id)
                            }}/>:null}
                            { this.state.func.edit ?<FontAwesomeIcon title='编辑' icon="fa fa-pen-to-square"
                                             size='xl'
                                             style={{
                                                 'color': '#d38269',
                                                 "cursor": "pointer",
                                                 "marginLeft": '5px'
                                             }} onClick={e => {
                                this.edit_sx(e, row.id)
                            }}/>:null}
                        </div>
                    }
                },
            ],
            xscj: [
                {value: '区、镇、村', label: '区、镇、村'},
                {value: '区、镇', label: '区、镇'},
                // {value: '区', label: '区'},
            ],
            "search_rule": [
                {
                    "label_name": "事项",
                    "type": "text",
                    "id": "search_sx",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                {
                    "label_name": "办理项名称",
                    "type": "text",
                    "id": "search_blxmc",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                // {
                //     "label_name": "行使层级",
                //     "type": "select",
                //     "id": "search_xscj",
                //     "value": "",
                //     "must": false,
                //     "nullable": false,
                //     "return_field":"value",
                //     "optionData":[{"value": "", "label": "请选择"}, {"value": "村", "label": "村"},{"value": "镇", "label": "镇"},{"value": "区", "label": "区"}]
                // }
            ],
            selectRows: {},
        }
    }

    componentDidMount() {
        this.get_all_sx()
    }

    add_sx = (e) => {
        PubSub.publish('Loding', true);
        let options = []
        http_call('/jzzwdt/itemtype:id,mc', 'GET').then(res => {
                res.DATA.forEach((val) => {
                    options.push({"value": val.id, "label": val.mc})
                })
            let options_1 = []
            http_call('/jzzwdt/nodifferenceitems:id,sxmc', 'GET').then(async res_1 => {
                res_1.DATA.forEach((val) => {
                    options_1.push({"value": val.id, "label": val.sxmc})
                })
                options = options.length ?  options: [{"value": 0, "label": "无数数据"}];
                options_1 = options_1.length ? options_1 : [{"value": 0, "label": "无数数据"}];
                let options_topic = await Promise.resolve( //获取主题
                    http_call('/jzzwdt/topic:id,ztmc', 'GET').then(res_2=>{
                        let options_3 = []
                        res_2.DATA.forEach((val) => {
                            options_3.push({"value": val.id, "label": val.ztmc})
                        })
                        return options_3;
                    })
                )
                options_topic = options_topic.length ? options_topic : [];
                PubSub.publish('Loding');

                this.setState({
                    "modal_show": true,
                    "modal_title": "新增办理项",
                    "modal_rules": [
                        {
                            "label_name": "事项",
                            "type": "select",
                            "id": "sxid",
                            "value": options_1[0],
                            "must": true,
                            "nullable": false,
                            "multi": false,
                            "placeholder": "请选择事项",
                            "field": "value",
                            "optionData": options_1,
                        },
                        {
                            "label_name": "事项类型",
                            "type": "select",
                            "id": "sxlxid",
                            "value": options[0],
                            "must": true,
                            "nullable": false,
                            "multi": false,
                            "placeholder": "请选择事项类型",
                            "field": "value",
                            "optionData": options,
                        },
                        {
                            "label_name": "办理项名称",
                            "type": "text",
                            "id": "blxmc",
                            "value": "",
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "行使层级",
                            "type": "select",
                            "id": "xscj",
                            "value": this.state.xscj[0],
                            "must": true,
                            "nullable": false,
                            // "multi": true,
                            "field": "value",
                            "optionData": this.state.xscj,
                        },
                        {
                            "label_name": "备注",
                            "type": "textarea",
                            "id": "bz",
                            "value": "",
                            "must": false,
                            "nullable": true,
                        },
                    ],
                    "modal_submit_title": "提交",
                    "modal_submit_callback": (e, data) => {
                        // data.ssztid = data.ssztid.join(',')
                        // data['xscj'] = data['xscj'].join(',');

                        http_call('/jzzwdt/nodifferencehandingitems', 'POST', data).then(res => {
                            let alert_info;
                            if (res.RTN_CODE === '01') {
                                alert_info = {
                                    "title": "提示",
                                    "content": res.RTN_MSG,
                                    "type": "success",
                                    "position": "top-right",
                                    "dismiss": 1000,
                                }
                                this.setState({"modal_show": false})
                                this.get_all_sx()
                            } else {
                                alert_info = {
                                    "title": "提示",
                                    "content": res.RTN_MSG,
                                    "type": "error",
                                    "position": "top-right",
                                    "dismiss": 1000,
                                }
                            }
                            PubSub.publish('AlertNotify', alert_info);
                        })
                    },
                });
            })
        })
    }

    edit_sx= (e, id) => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/nodifferencehandingitems', 'GET', {'id': id}).then(res => {
            let options = []
            let option = {}
            options.push(option)
            // let xscj = {"value": "",  "label": '请选择'};
            // let xscj = [{"value": "",  "label": '请选择'}];
            // if (res.DATA[0].xscj != "") {
            //     xscj = res.DATA[0].xscj.split(',').map(val => {
            //         return {"value": val, "label": val}
            //     })
            // }
            let options_1 = []
            // 选择事项
            http_call('/jzzwdt/nodifferenceitems:id,sxmc', 'GET').then(async res_1 => {
                res_1.DATA.forEach((val) => {
                    options_1.push({"value": val.id, "label": val.sxmc})
                })
            http_call('/jzzwdt/itemtype:id,mc', 'GET').then(res_1 => {
                res_1.DATA.forEach((val) => {
                    options.push({"value": val.id, "label": val.mc});
                    if (val.id === res.DATA[0].sxlxid) {
                        option = {"value": val.id, "label": val.mc};
                    }
                })
                let options_2 = []
                let option_2 = {}
                options_2.push(option_2)
                http_call('/jzzwdt/items:id,sxmc', 'GET').then(async res_2 => {
                    res_2.DATA.forEach((val) => {
                        options_2.push({"value": val.id, "label": val.sxmc});
                        if (val.id === res.DATA[0].sxid) {
                            option_2 = {"value": val.id, "label": val.sxmc};
                        }
                    })
                    options = options.length ? options : [{"value": 0, "label": "无数数据"}];
                    options_2 = options_2.length ? options_2 : [{"value": 0, "label": "无数数据"}];
                    // let selected_topic_options = []
                    let options_topic = await Promise.resolve( //获取主题
                    http_call('/jzzwdt/topic:id,ztmc', 'GET').then(res_2=>{
                        let options_3 = []
                        // let tmp = res.DATA[0].ssztid.split(',')
                        res_2.DATA.forEach((val) => {
                            options_3.push({"value": val.id, "label": val.ztmc})
                            // tmp.forEach(ztid=>{
                            //     if (parseInt(ztid) === val.id)
                            //        selected_topic_options.push({"value": val.id, "label": val.ztmc})
                            // })
                        })
                        return options_3;
                    })
                    )
                    PubSub.publish('Loding');
                    options_topic = options_topic.length ? options_topic : [];
                    this.setState({
                        "modal_show": true,
                        "modal_title": "修改事项",
                        "modal_rules": [
                            {
                                "label_name": "事项id",
                                "type": "hidden",
                                "id": "id",
                                "value": id,
                                "must": true,
                                "nullable": false,
                            },
                            {
                                "label_name": "事项名称",
                                "type": "select",
                                "id": "sxid",
                                "field":"value",
                                "value": {value: res.DATA[0].sxid, label: res.DATA[0].sxmc},
                                "must": true,
                                "nullable": false,
                                "optionData":options_1
                            },
                            {
                                "label_name": "事项类型",
                                "type": "select",
                                "id": "sxlxid",
                                "value": option,
                                "field":"value",
                                "must": true,
                                "nullable": false,
                                "optionData":options
                            },
                            {
                                "label_name": "办理项名称",
                                "type": "text",
                                "id": "blxmc",
                                "value": res.DATA[0].blxmc,
                                "field":"value",
                                "must": true,
                                "nullable": false,
                            },
                            {
                                "label_name": "行使层级",
                                "type": "select",
                                "id": "xscj",
                                "value": {value: res.DATA[0].xscj, label: res.DATA[0].xscj},
                                "must": true,
                                "field":"value",
                                "nullable": false,
                                // "multi": true,
                                "optionData": this.state.xscj,
                            },
                            {
                                "label_name": "备注",
                                "type": "textarea",
                                "id": "bz",
                                "value": res.DATA[0].bz,
                                "must": false,
                                "nullable": true,
                            },
                        ],
                        "modal_submit_title": "提交",
                        "modal_submit_callback": (e, data) => {
                            // data.xscj = data.xscj.join(',')
                            http_call('	/jzzwdt/nodifferencehandingitems/edit', 'POST', data).then(res => {
                                let alert_info;
                                if (res.RTN_CODE === '01') {
                                    alert_info = {
                                        "title": "提示",
                                        "content": res.RTN_MSG,
                                        "type": "success",
                                        "position": "top-right",
                                        "dismiss": 1000,
                                    }
                                    this.setState({"modal_show": false})
                                    this.get_all_sx()
                                } else {
                                    alert_info = {
                                        "title": "提示",
                                        "content": res.RTN_MSG,
                                        "type": "error",
                                        "position": "top-right",
                                        "dismiss": 1000,
                                    }
                                }
                                PubSub.publish('AlertNotify', alert_info);
                            })
                        },
                    });
                })
            })
        })
        })
    }

    del_sx= async (id) => {
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
            {
                "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
                "okText": "确认",
                okButtonStyle: 'warning',
                "cancelText": "取消"
            });
        if (result) {
            http_call('/jzzwdt/nodifferencehandingitems/delete', 'POST', {'id': id}).then(res => {
                let alert_info;
                if (res.RTN_CODE === '01') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    this.setState({"modal_show": false})
                    this.get_all_sx()
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }

    get_all_sx = () => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/nodifferencehandingitems', 'GET').then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    inquiry_sx = (data) => {
        PubSub.publish('Loding', true);
        let DATA = {}
        Object.keys(data).forEach((key)=>{
            DATA[key.replace('search_', '')] = data[key];
        })
        http_call('/jzzwdt/nodifferencehandingitems', 'GET', DATA).then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    render() {
        return (
            <div className='page-inner'>
                <div className="page-header">
                    <h4 className="page-title">{this.state.name}</h4>
                    <ul className="breadcrumbs">
                        <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>无差别服务</span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>{this.state.name}</span>
                        </li>
                    </ul>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                { this.state.func.add ?<button className='btn btn-primary btn-xs'
                                        onClick={e => {
                                            this.add_sx(e);
                                        }
                                        }>新增办理项
                                </button>:null}
                                <TableSearchFields rule={this.state.search_rule} onSearch={(data) => {this.inquiry_sx(data)}}/>
                            </div>
                            <DataTable
                                noDataComponent=<h1
                                style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>
                            columns={this.state.column}
                            data={this.state.tableData}
                            selectableRows
                            progressPending={this.state.pending}
                            pagination
                            paginationComponentOptions = {{
                                rowsPerPageText:'每页行数:',
                                rangeSeparatorText: '/',
                                selectAllRowsItem: true,
                                selectAllRowsItemText: '全部'
                            }}
                            fixedHeader
                            fixedHeaderScrollHeight={"100vh"}
                            theme=""
                            onSelectedRowsChange={(rows) => {
                            this.setState({"selectRows": rows})
                        }}
                            />
                        </div>
                    </div>
                </div>
                {this.state.modal_show ?
                    <DynamicModal show={this.state.modal_show}
                                  rules={this.state.modal_rules}
                                  title={this.state.modal_title}
                                  submit_title={this.state.modal_submit_title}
                                  close_callback={e => {
                                      this.setState({"modal_show": false})
                                  }}
                                  submit_callback={this.state.modal_submit_callback}
                    />
                    : null
                }
            </div>
        );
    }
}
