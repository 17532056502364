import React from "react";
import DataTable from 'react-data-table-component';
import $ from 'jquery';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PubSub from 'pubsub-js';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import {http_call, func_privileges} from '../../../../../libs/utils';
import {confirm} from 'react-bootstrap-confirmation';


class AreaManagement extends React.Component {
    constructor(props) {
        super(props);
        let func = {"add": 108, 'del': 109, 'edit': 110, 'get': 111}
        func = func_privileges(func)
        this.state = {
            "func": func,
            "name": '区划管理',
            "column": [
                {
                    "name": "ID",
                    "selector": row => row.id,
                },
                {
                    "name": "区划名称",
                    "selector": row => row.qymc
                },
                {
                    "name": "行政级别",
                    "selector": row => row.xzjb
                },
                {
                    "name": "区域中心坐标",
                    "selector": row => row.qyzxzb
                },
                {
                    "name": "上级区划",
                    "selector": row => row.parent_name
                },
                {
                    "name": "操作",
                    "width": "100px",
                    "selector": row => {
                        return <div style={{"minWidth": "150px"}}>
                            {this.state.func.del ? <FontAwesomeIcon title='删除' icon="fa fa-trash"
                                                                    size='xl'
                                                                    style={{
                                                                        'color': 'red',
                                                                        "cursor": "pointer"
                                                                    }} onClick={e => {
                                this.del(row.id)
                            }}/> : null}
                            {this.state.func.edit ? <FontAwesomeIcon title='编辑' icon="fa fa-pen-to-square"
                                                                     style={{
                                                                         'color': '#d38269',
                                                                         "cursor": "pointer",
                                                                         "marginLeft": '5px'
                                                                     }}
                                                                     size='xl' onClick={e => {
                                this.show_edit_qh(e, row.id)
                            }}/> : null}
                        </div>
                    }
                },

            ],
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            "search_rule": [
                {
                    "label_name": "区划名称",
                    "type": "text",
                    "id": "qymc",
                    "value": "",
                    "must": false,
                    "nullable": false,
                }
            ],
            "selectRows": {},
        }
    }

    componentDidMount() {
        this.get_all_area()
    }

    // 获取区域数据
    get_all_area = () => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/area', 'GET').then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    show_add_qh = (e) => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/area:id,qymc', 'GET').then(res => {
            let options = [{"value": '', "label": "无上级区划"}]
            res.DATA.forEach((val) => {
                options.push({"value": val.id, "label": val.qymc})
            })
            PubSub.publish('Loding');
            this.setState({
                "modal_show": true,
                "modal_title": "新增区划",
                "modal_rules": [
                    {
                        "label_name": "区划名称",
                        "type": "text",
                        "id": "qhmc",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "行政级别",
                        "type": "select",
                        "id": "xzjb",
                        "value": '',
                        "must": true,
                        "nullable": false,
                        "field": "value",
                        "optionData": [{"value": "村", "label": "村"}, {"value": "镇", "label": "镇"}, {
                            "value": "区",
                            "label": "区"
                        }]
                    },
                    {
                        "label_name": "上级区划",
                        "type": "select",
                        "id": "parentid",
                        "value": {"value": 0, "label": "无上级区划"},
                        "must": true,
                        "nullable": false,
                        "multi": false,
                        "placeholder": "请选择区划",
                        "field": "value",
                        "optionData": options,
                    },
                    {
                        "label_name": <span>区划中心坐标(<a
                            href='https://lbs.amap.com/tools/picker' rel="noopener noreferrer" target='_blank'>坐标拾取器</a>)</span>,
                        "type": "text",
                        "id": "qyzxzb",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                ],
                "modal_submit_title": "提交",
                "modal_submit_callback": (e, data) => {
                    e.target.disabled = true;
                    data['qymc'] = data.qhmc
                    delete data.qhmc
                    http_call('/jzzwdt/area', 'POST', data).then(res => {
                        e.target.disabled = false;
                        let alert_info;
                        if (res.RTN_CODE === '01') {
                            alert_info = {
                                "title": "提示",
                                "content": res.RTN_MSG,
                                "type": "success",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                            this.setState({"modal_show": false})
                            this.get_all_area()
                        } else {
                            alert_info = {
                                "title": "提示",
                                "content": res.RTN_MSG,
                                "type": "error",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                        }
                        PubSub.publish('AlertNotify', alert_info);
                    })
                },
            });
        })
    }

    show_edit_qh = (e, id) => {
        PubSub.publish('Loding', true);
        e.target.disabled = true
        http_call('/jzzwdt/area:id,qymc', 'GET').then(res => {
            let options = [{"value": 0, "label": "无上级区划"}]
            res.DATA.forEach((val) => {
                options.push({"value": val.id, "label": val.qymc})
            })
            http_call('/jzzwdt/area', 'GET', {'id': id}).then(res => {
                let currrent_select = {"value": res.DATA[0].parentid, 'label': res.DATA[0].parent_name};
                if (res.DATA[0].parentid === 0) {
                    currrent_select = {"value": 0, "label": "无上级区划"}
                }
                e.target.disabled = false
                PubSub.publish('Loding');
                this.setState({
                    "modal_show": true,
                    "modal_title": "修改区划",
                    "modal_rules": [
                        {
                            "label_name": "区划ID",
                            "type": "hidden",
                            "id": "id",
                            "value": id,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "区划名称",
                            "type": "textarea",
                            "id": "qhmc",
                            "value": res.DATA[0].qymc,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "行政级别",
                            "type": "select",
                            "id": "xzjb",
                            "value": {"value": res.DATA[0].xzjb, "label": res.DATA[0].xzjb},
                            "must": true,
                            "nullable": false,
                            "field": "value",
                            "optionData": [{"value": "村", "label": "村"}, {
                                "value": "镇",
                                "label": "镇"
                            }, {"value": "区", "label": "区"}]
                        },
                        {
                            "label_name": "上级区划",
                            "type": "select",
                            "id": "parentid",
                            "value": currrent_select,
                            "must": true,
                            "nullable": false,
                            "multi": false,
                            "placeholder": "请选择区划",
                            "field": "value",
                            "optionData": options,
                        },
                        {
                            "label_name": <span>区划中心坐标(<a rel="noopener noreferrer" href='https://lbs.amap.com/tools/picker'
                                                                target='_blank'>坐标拾取器</a>)</span>,
                            "type": "text",
                            "id": "qyzxzb",
                            "value": res.DATA[0].qyzxzb,
                            "must": true,
                            "nullable": false,
                        },
                    ],
                    "modal_submit_title": "提交修改",
                    "modal_submit_callback": (e, data) => {
                        e.target.disabled = true;
                        data['qymc'] = data.qhmc
                        delete data.qhmc
                        http_call('/jzzwdt/area/edit', 'POST', data).then(res => {
                            e.target.disabled = false;
                            let alert_info;
                            if (res.RTN_CODE === '01') {
                                alert_info = {
                                    "title": "提示",
                                    "content": res.RTN_MSG,
                                    "type": "success",
                                    "position": "top-right",
                                    "dismiss": 1000,
                                }
                                this.setState({"modal_show": false})
                                this.get_all_area()
                            } else {
                                alert_info = {
                                    "title": "提示",
                                    "content": res.RTN_MSG,
                                    "type": "error",
                                    "position": "top-right",
                                    "dismiss": 1000,
                                }
                            }
                            PubSub.publish('AlertNotify', alert_info);
                        }).catch(() => {
                            e.target.disabled = false
                        })
                    },
                });
            }).catch(() => {
                e.target.disabled = false
            })
        }).catch(() => {
            e.target.disabled = false
        })
    }
    del = async id => {
        const result = await confirm(<h2 className='text-center text-danger'>确认该区划删除?</h2>,
            {
                "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
                "okText": "确认",
                okButtonStyle: 'warning',
                "cancelText": "取消"
            });
        if (result) {
            http_call('/jzzwdt/area/delete', 'POST', {'id': id}).then(res => {
                let alert_info;
                if (res.RTN_CODE === '01') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    this.setState({"modal_show": false})
                    this.get_all_area()
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }


    render() {
        return <div className='page-inner'>
            <div className="page-header">
                <h4 className="page-title">{this.state.name}</h4>
                <ul className="breadcrumbs">
                    <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                    </li>
                    <li className="separator">
                        <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                    </li>
                    <li className="nav-item">
                        <span>{this.state.name}</span>
                    </li>
                </ul>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                            {
                                this.state.func.add ? <button className='btn btn-primary btn-xs'
                                                              onClick={e => {
                                                                  this.show_add_qh(e);
                                                              }
                                                              }>新增区划
                                    </button>
                                    : null}
                            {this.state.func.get ?
                                <TableSearchFields rule={this.state.search_rule}
                                                   onSearch={(data) => {
                                                       http_call('/jzzwdt/area', 'GET', data).then(res => {
                                                           this.setState({"tableData": res.DATA})
                                                       })
                                                   }}/> : null
                            }
                        </div>
                        {this.state.func.get ?
                            <DataTable
                                noDataComponent=<h1
                                style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>
                            columns = {this.state.column}
                            data={this.state.tableData}
                            selectableRows
                            progressPending={this.state.pending}
                            pagination
                            paginationComponentOptions = {{
                            rowsPerPageText:'每页行数:',
                            rangeSeparatorText: '/',
                            selectAllRowsItem: true,
                            selectAllRowsItemText: '全部'
                        }}
                            fixedHeader
                            fixedHeaderScrollHeight={$(window).height() + 'px'}
                            theme=""
                            onSelectedRowsChange={(rows) => {
                            this.setState({"selectRows": rows})
                        }}
                            /> : null}
                            </div>
                            </div>
                            </div>
                        {this.state.modal_show ?
                            <DynamicModal show={this.state.modal_show}
                            rules={this.state.modal_rules}
                            title={this.state.modal_title}
                            submit_title={this.state.modal_submit_title}
                            close_callback={e => {
                            this.setState({"modal_show": false})
                        }}
                            submit_callback={this.state.modal_submit_callback}
                            />
                            : null
                        }
                            </div>
                        }
                        }
export default AreaManagement;