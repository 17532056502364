import React from "react";
import Select from 'react-select';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import style from './css/style.module.css';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import PubSub from 'pubsub-js';
import Button from 'react-bootstrap/Button';
// richtext
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // 引入样式文件
// import TableUI from 'quill-table-ui';
// import 'quill-table-ui/dist/index.css';

let selected_data = {}

function modal_submit(e, props) {
    let data = {};
    let all_val = {};
    for (let i = 0; i < props.rules.length; i++) {
        let item = props.rules[i];
        let val = $("#" + item.id).val().trim();
        if (item.type === 'select') {
            val = selected_data[item.id]
            if (item.multi) {
                if (val === undefined) {
                    val = []
                }
                if (item.nullable !== undefined && !item.nullable) {
                    if (val.length === 0) {
                        let alert_info = {
                            "title": "提示",
                            "content": item.label_name + '必选至少一个',
                            "type": "error",
                            "position": "top-right",
                            "dismiss": 3000,
                        }
                        PubSub.publish('AlertNotify', alert_info);
                        return
                    }
                }
            }
            else {
                if (val === undefined) {
                    if (item.field) {
                        val = ''
                    }
                    else {
                        val = {}
                    }
                }
                if (typeof (val) === 'string'){
                    if (val === '' && ! item.nullable) {
                        let alert_info = {
                            "title": "提示",
                            "content": item.label_name + '必选一个',
                            "type": "error",
                            "position": "top-right",
                            "dismiss": 3000,
                        }
                        PubSub.publish('AlertNotify', alert_info);
                        return
                    }
                }
                else if ((val.value === '' || val.value === undefined) && item.nullable) {
                        let alert_info = {
                        "title": "提示",
                        "content": item.label_name + '必选一个',
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 3000,
                    }
                    PubSub.publish('AlertNotify', alert_info);
                    return
                }
            }
        }
        if (item.type === 'richtext'){
            val = $("#" + item.id + ">div>div")[0].innerHTML
            if (val === '<p><br></p>') val = ''
        }
        all_val[item.id] = val
        if (item.nullable !== undefined && !item.nullable) {
            if (val === '') {
                let alert_info = {
                    "title": "提示",
                    "content": item.label_name + '不可为空',
                    "type": "error",
                    "position": "top-right",
                    "dismiss": 3000,
                }
                PubSub.publish('AlertNotify', alert_info);
                return
            }
        }
        if (item.must || item.nullable) { // 必填或者可以为空 吐出数据 
            data[item.id] = val
        }
    }
    // 自定义检测
    for (let i = 0; i < props.rules.length; i++) {
        if (typeof (props.rules[i].check_parameters) === 'function') {
            if (!props.rules[i].check_parameters(all_val, all_val[props.rules[i].id])) {
                return
            }
        }
    }
    props.submit_callback(e, data)
}

export default function DynamicModal(props) {
    return <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={props.show}
    >
        <Modal.Header>
            <Modal.Title>
                {props.title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                generate_body(props)
            }
        </Modal.Body>
        <Modal.Footer>
            {
                generate_footer(props)
            }
        </Modal.Footer>
    </Modal>
}


function generate_body(props) {
    return props.rules.map(item => {
        if (typeof (item.callback) === 'function') {
            console.log(item)
            return <div key={item.id} className="form-group row">
                    <label htmlFor={item.id} className="col-form-label" style={{
                        "letterSpacing": '2px',
                        "lineHeight": "2.5em",
                        "padding": '5px 0px 0px 5px',
                        "margin": 0
                    }}>
                        { !item.nullable ? <div><span style={{"color": "red"}}>*</span>{item.label_name}</div> : item.label_name}
                    </label>
                    <div className="col-md-12">
                        <div key={item.id}>{item.callback(item)}</div>
                    </div>
            </div>
        }
        else if (item.type === 'select') {
            return <div key={item.id} className="form-group row">
                <label htmlFor={item.id} className="col-form-label" style={{
                    "letterSpacing": '2px',
                    "lineHeight": "2.5em",
                    "padding": '5px 0px 0px 5px',
                    "margin": 0
                }}>
                        { !item.nullable ? <div><span style={{"color": "red"}}>*</span>{item.label_name}</div> : item.label_name}
                </label>
                <div style={{"padding": "3px 5px 0px 5px"}}>
                    <Select
                        onChange={e => {
                            let tmp_data = []
                            if (item.field === 'label') {
                                if (item.multi) {
                                    e.forEach(val => {
                                        tmp_data.push(val.label)
                                    })
                                    selected_data[item.id] = tmp_data
                                } else {
                                    selected_data[item.id] = e.label
                                }
                            } else if (item.field === 'value') {
                                if (item.multi) {
                                    e.forEach(val => {
                                        tmp_data.push(val.value)
                                    })
                                    selected_data[item.id] = tmp_data
                                } else {
                                    selected_data[item.id] = e.value
                                }
                            } else
                                selected_data[item.id] = e
                            if (typeof (item.onchange) === 'function') {
                                item.onchange(e)
                            }
                        }}
                        defaultValue={()=>{
                            let tmp_data = []
                            if (item.field === 'label') {
                                if (item.multi) {
                                    item.value.forEach(val => {
                                        tmp_data.push(val.label)
                                    })
                                    selected_data[item.id] = tmp_data
                                } else {
                                    selected_data[item.id] = item.value.label
                                }
                            } else if (item.field === 'value') {
                                if (item.multi) {
                                    item.value.forEach(val => {
                                        tmp_data.push(val.value)
                                    })
                                    selected_data[item.id] = tmp_data
                                } else {
                                    selected_data[item.id] = item.value.value
                                }
                            } else
                                selected_data[item.id] = item.value
                            return item.value
                        }}
                        // defaultValue={()=>{selected_data[item.id] = item.value;return item.value}}
                        isMulti={item.multi}
                        id={item.id}
                        options={item.optionData}
                        placeholder={item.placeholder === undefined ? '请选择' : item.placeholder}
                        style={{'zIndex': 2000}}
                        className="select_bottom_line basic-multi-select "
                        classNamePrefix="select"
                    />
                </div>
            </div>
        }
        // return <div className="form-group form-floating-label">
        //     <input type={item.type} id={item.id} placeholder={item.label_name}
        //            defaultValue={item.value} disabled={item.disable ? 'true' : ''}
        //            className="form-control input-border-bottom"/>
        // </div>
        else if (item.type === 'password') {
            return <div key={item.id} className="form-group row">
                <label htmlFor={item.id} className="col-form-label" style={{
                    "letterSpacing": '2px',
                    "lineHeight": "2.5em",
                    "padding": '5px 0px 0px 5px',
                    "margin": 0
                }}>
                    { ! item.nullable ? <div><span style={{"color": "red"}}>*</span>{item.label_name}</div> : item.label_name}
                </label>
                <div className="col-md-12">
                    <form>
                        <input type={item.type} id={item.id} placeholder={item.placeholder}
                               defaultValue={item.value} disabled={item.disable ? 'true' : ''}
                               className="table-search-input form-control input-border-bottom"
                               onChange={e => {
                                   if (typeof (item.onchange) === 'function') {
                                       item.onchange(e)
                                   }
                               }} autoComplete='new-password'/>
                    </form>
                </div>
            </div>
        }
        else if (item.type === 'textarea') {
            return <div key={item.id} className="form-group row">
                <label htmlFor={item.id} className="col-form-label" style={{
                    "letterSpacing": '2px',
                    "lineHeight": "2.5em",
                    "padding": '5px 0px 0px 5px',
                    "margin": 0
                }}>
                    { ! item.nullable ? <div><span style={{"color": "red"}}>*</span>{item.label_name}</div> : item.label_name}
                </label>
                <div className="col-md-12">
                        <textarea type={item.type} id={item.id} placeholder={item.placeholder}
                               defaultValue={item.value}
                               disabled={item.disable ? 'true' : ''}
                               className="table-search-input input-border-bottom form-control"
                               onChange={e => {
                                   if (typeof (item.onchange) === 'function') {
                                       item.onchange(e)
                                   }
                               }} autoComplete='new-password'
                              style={{"height": "unset !important"}}
                        />
                </div>
            </div>
        }
        else if (item.type === 'richtext'){
            return <div key={item.id} className="form-group row">
                <label htmlFor={item.id} className="col-form-label" style={{
                    "letterSpacing": '2px',
                    "lineHeight": "2.5em",
                    "padding": '5px 0px 0px 5px',
                    "margin": 0
                }}>
                    { ! item.nullable ? <div><span style={{"color": "red"}}>*</span>{item.label_name}</div> : item.label_name}
                </label>
                <div className="col-md-12">
                    <ReactQuill
                        id={item.id}
                        value={item.value}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, false] }],
                            ['bold', 'italic', 'underline'],
                            ['image', 'code-block'],
                            [{ 'align': [] }],
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            ['clean'],
                            [{ 'table': 'true' }],
                          ],
                          // tableUI: true, // 将表格插件启用
                        }}
                        readOnly={item.readonly ? true : false}
                        onChange={typeof (item.onchange) === 'function' ? (e, content, delta, source, editor)=>{item.onchange(e, content, delta, source, editor)} : null}
                        placeholder={item.placeholder || '请输入'}
                    />
                </div>
            </div>
        }
        return <div key={item.id} className="form-group row" style={item.type === 'hidden' ? {"display": 'none'} : {}}>
            <label htmlFor={item.id} className="col-form-label"
                   style={{"letterSpacing": '2px', "lineHeight": "2.5em", "padding": '5px 0px 0px 5px', "margin": 0}}>
                    { ! item.nullable ? <div><span style={{"color": "red"}}>*</span>{item.label_name}</div> : item.label_name}
            </label>
            <div className="col-md-12">
                <input type={item.type} id={item.id} placeholder={item.placeholder}
                       defaultValue={item.value}
                       disabled={item.disable ? 'true' : ''}
                       className="table-search-input form-control input-border-bottom"
                       onChange={e => {
                           if (typeof (item.onchange) === 'function') {
                               item.onchange(e)
                           }
                       }}/>
            </div>
        </div>
    })
}

function generate_footer(props) {
    if (typeof (props.footer_callback) === 'function') {
        return <div>props.footer_callback(e)</div>
    } else {
        return <div>
            <Button className='btn-danger'
                    onClick={e => {
                        props.close_callback(e)
                    }
                    } size='sm'>关闭</Button>
            {props.submit_title !== '' ?
            <Button onClick={e => {
                modal_submit(e, props)
            }} size='sm'>{props.submit_title}</Button> :null}
        </div>
    }
}