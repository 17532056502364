import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class Logo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {"img_src": props.img_src, "text": props.text || '', "topbar_toggled": false}
    }

    toggle_click = (e) => {
        this.setState({toggled: !this.state.toggled});
        if (!this.props.logo_toggle) {
            this.props.set_logo_toggle(true);
            this.props.wrapStats("main-header sidebar_minimize nav_open")
        } else {
            this.props.set_logo_toggle(false);
            this.props.wrapStats("main-header")
        }
        // e.target.className = 'svg-inline--fa fa-ellipsis-vertical ';
    }

    topbar_toggle_click = (e) => {
        this.setState({topbar_toggled: !this.state.topbar_toggled});
        if (!this.state.topbar_toggled) {
            this.props.wrapStats("main-header sidebar_minimize topbar_open")
        } else {
            this.props.wrapStats("main-header")
        }
        // e.target.className = 'svg-inline--fa fa-ellipsis-vertical ';
    }

    render() {
        let images = this.state.img_src || '';
        let text = this.state.text || '';
        let click_to = this.state.img_src || '#';
        if (text !== '') {
            return (
                <div className="logo-header" data-background-color={this.props.theme.nav}>
                    <a href={click_to} style={{"textDecoration": "none"}} className='logo'>
                        <h1 className="text-center" style={{"color": "white", "paddingTop": "10px"}}>{text}</h1>
                    </a>
                    <button className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse"
                            data-target="collapse" aria-expanded="false" aria-label="Toggle navigation"
                            onClick={this.toggle_click}>
                    <span className="navbar-toggler-icon">
                        {this.props.logo_toggle ? <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical"/> :
                            <FontAwesomeIcon icon="fa-solid fa-bars"/>
                        }
                    </span>
                    </button>
                    <button className="topbar-toggler more" onClick={this.topbar_toggle_click}>
                        {
                            this.props.topbar_toggled ? <FontAwesomeIcon icon="fa-solid fa-bars "/> :
                                <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical"/>
                        }                </button>
                    <div className="nav-toggle">
                        <button className="btn btn-toggle toggle-sidebar" onClick={this.toggle_click}>
                            {
                                this.props.logo_toggle ? <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical"/> :
                                    <FontAwesomeIcon icon="fa-solid fa-bars"/>
                            }
                        </button>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="logo-header" data-background-color="blue">
                    <a href={click_to} className="logo">
                        <img src={images} alt="navbar brand" className="navbar-brand"/>
                    </a>
                    <button className="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse"
                            data-target="collapse" aria-expanded="false" aria-label="Toggle navigation"><span
                        className="navbar-toggler-icon">
{
    this.props.logo_toggle ? <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" onClick={this.toggle_click}/> :
        <FontAwesomeIcon icon="fa-solid fa-bars" onClick={this.toggle_click}/>
}            </span></button>
                    <button className="topbar-toggler more" onClick={this.topbar_toggle_click}>
                        {
                            this.props.topbar_toggled ? <FontAwesomeIcon icon="fa-solid fa-bars "/> :
                                <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical"/>
                        }                </button>
                    <div className="nav-toggle">
                        <button className="btn btn-toggle toggle-sidebar">
                            {
                                this.props.logo_toggle ? <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical"
                                                                      onClick={this.toggle_click}/> :
                                    <FontAwesomeIcon icon="fa-solid fa-bars" onClick={this.toggle_click}/>
                            }                    </button>
                    </div>
                </div>
            )
        }
    }

}


export default Logo;