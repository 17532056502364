import React from "react";
import '../css/style.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './css/style.css';
import {api_call} from '../../../../libs/utils.js';
import PubSub from 'pubsub-js';
import DataTable from 'react-data-table-component';
import Select from 'react-select';



class MenuFunctions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "name": "系统功能管理",
            "column": [
                {
                    "name": "ID",
                    "selector": row => row.functionid,
                },
                {
                    "name": "名称",
                    "selector": row => {
                        return <input readOnly defaultValue={row.functionname} value={row.functionname} onClick={e=>{this.input_handleClick(e, row, 'functionname')}} onBlur={e=>{this.input_blur(e, row, 'functionname')}} className="edit_input"/>
                    },
                },
                {
                    "name": "上线时间",
                    "selector": row => {
                        return <input readOnly value={row.onlinetime} onClick={e=>{this.input_handleClick(e, row, 'onlinetime')}} onBlur={e=>{this.input_blur(e, row, 'onlinetime')}} className="edit_input"/>
                    },
                },
                {
                    "name": "描述",
                    "selector": row => {
                        return <input readOnly value={row.description} onClick={e=>{this.input_handleClick(e, row, 'description')}} onBlur={e=>{this.input_blur(e, row, 'description')}} className="edit_input"/>
                    },
                },
                {
                    "name": "所属菜单",
                    "selector": row => {
                        return <div onClick={e=>{this.select_handleClick(e, row, 'menu_id')}} onMouseOut={e=>{this.select_blur(e)}}>
                                <select disabled style={{"appearance": "none"}} className="td_select" onChange={e=>{this.select_change(e, row, 'menu_id')}}>
                                    {this.state.menus.map((item, idx)=>{
                                        if(item.link_id === row.menu_id)
                                            return <option key={idx} selected defaultValue={item.link_id} value={item.link_id}>{item.name}</option>
                                        return <option key={idx} defaultValue={item.link_id} value={item.link_id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                    },
                },
                {
                    "name": "操作",
                    "selector": row => {
                        return <div><FontAwesomeIcon title='删除' icon="fa fa-trash" size='xl' style={{'color': 'red', "cursor": "pointer"}} onClick={e => {
                                this.del(row.functionid)
                            }}/>
                                <button className='btn btn-xs btn-success'
                                        style={{"marginLeft": '5px', "maxHeight": '30px'}}
                                        onClick={e=>{this.show_apimanagement_modal(e, row)}}>API管理</button>
                            </div>
                    }
                }
            ],
            "show_modal": false,
            "modal_title": "",
            "modal_submit_callback": "",
            "tableData": [],
            'menus': [],
            'show_apimanagement_modal': false,
            'show_apimanagement_modal_title': '',
            'apimanagement_column': [
                {
                    "name": "ID",
                    "selector": row => row.apiid,
                },
                {
                    "name": "接口名称",
                    "selector": row => row.apiname,
                },
                {
                    "name": "地址",
                    "selector": row => row.apipath,
                },
                {
                    "name": "接口描述",
                    "selector": row => row.description,
                },
                {
                    "name": "操作",
                    "selector": row => {
                        return <FontAwesomeIcon title='删除' icon="fa fa-trash" size='xl'
                                                style={{'color': 'red', "cursor": "pointer"}} onClick={e => {
                            this.del_function_api(row.functionid, row.apiid);
                        }}/>
                    },
                }

            ],
            'apimanagement_data': [],
            'apimanagement_select_data': [],
            'apimanagement_selected_data': [],
        }
    }

    del_function_api_callback = (res)=>{
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 3000,
            }
        } else {
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 1000,
            }
        }
        api_call('/apimanagement/', 'GET', this.set_apimanagement_select_data,{},{"functionid": this.state.current_functionid})
        PubSub.publish('AlertNotify', alert_info);
    }

    del_function_api = (functionid, apiid)=>{
        api_call('/menufunctions/del_function_api', 'POST', this.del_function_api_callback, {"functionid": functionid, 'apiid': apiid})
    }


    set_tableData = (res) => {
        this.setState({"tableData": res.DATA});
    }
    set_menus = (res) => {
        this.setState({"menus": res.DATA});
    }

    componentDidMount() {
        api_call('/menus/get_all_menus:link_id,name', 'GET', this.set_menus);
        api_call('/menufunctions', 'GET', this.set_tableData);
    }

    edit_callback = (res, data) => {
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 1000,
            }
        } else {
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 3000,
            }
        }
        api_call('/menufunctions', 'GET', this.set_tableData)
        PubSub.publish('AlertNotify', alert_info);
    }

    input_blur = (e, row, field)=>{
        $(e.target).removeClass('on_edit_input');$(e.target).attr('readonly', 'readonly');
        if ($(e.target).val().trim() !== row[field]){
            api_call('/menufunctions/update_func', 'POST', this.edit_callback, {"functionid": row.functionid, [field]: $(e.target).val().trim()})
        }
    }

    input_handleClick = (e, row, field) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                $(e.target).addClass('on_edit_input')
                $(e.target).removeAttr('readOnly')
                break;
            case 3:
                break;
            default:
                break;
        }
    }

    select_blur = (e) =>{
        $(e.target).css('appearance', 'none');
        $(e.target).attr('disabled', 'disabled');
    }
    select_change = (e, row, field)=>{
        $(e.target).css('appearance', 'none');
        $(e.target).attr('disabled', 'disabled');
        if ($(e.target).val().trim() !== row[field]){
            api_call('/menufunctions/update_func', 'POST', this.edit_callback, {"functionid": row.functionid, [field]: $(e.target).val().trim()})
        }
    }
    select_handleClick = (e, row) => {
        switch (e.detail) {
            case 1:
                break;
            case 2:
                $(e.target).removeAttr('disabled')
                $(e.target).css('appearance', '')
                break;
            case 3:
                break;
            default:
                break;
        }
    }

    close_modal = () => {
        this.setState({"show_modal": false})
    }

    show_add_modal = () => {
        this.setState({"show_modal": true, "modal_title": "新增功能", "modal_submit_callback": this.add});
    }

    set_apimanagement_data = (res)=>{
        this.setState({"apimanagement_data": res.DATA, });
    }

    // 已经选中的接口
    set_apimanagement_selected_data = (res, data)=>{
        let OptionData = [];
        let apimanagement_data = []
        data.data.forEach((item)=>{
            let flag = false
            res.DATA.forEach((api)=>{
                if (api.apiid === item.apiid){
                    item['functionid'] = data.functionid
                    apimanagement_data.push(item)
                    flag = true
                }
            })
            if (!flag){
                OptionData.push({"value": item.apiid, "label": item.apiname, "functionid": data.functionid});
            }
        })
        this.setState({"apimanagement_select_data": OptionData, 'apimanagement_data': apimanagement_data});
        this.setState({"show_apimanagement_modal": true, "current_functionid": data.functionid})
    }

    //接口选择器变动
    api_select_change = (data)=>{
        this.setState({"apimanagement_selected_data": data});
    }

    //接口绑定回调函数
    submit_bind_callback = (res, data) => {
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 3000,
            }
        } else {
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 1000,
            }
        }
        $(data.e.target).removeAttr("disabled")
        api_call('/apimanagement/', 'GET', this.set_apimanagement_select_data,{},{"functionid": this.state.current_functionid})
        PubSub.publish('AlertNotify', alert_info);
    }
    submit_bind_api = (e)=>{
        if (this.state.apimanagement_selected_data.length === 0) return;
        $(e.target).attr("disabled", 'disabled')
        this.state.apimanagement_selected_data.forEach(item=>{
            api_call('/menufunctions/add_function_apis', 'POST', this.submit_bind_callback, {"functionid": this.state.current_functionid, 'apiid': item.value}, {'e': e})
        })
    }

    set_apimanagement_select_data = (res, data)=>{
        let OptionData = []
        res.DATA.forEach((item)=>{
            OptionData.push({"value": item.apiid, "label": item.apiname})
        })
        api_call('/menufunctions/get_function_apis?functionid=' + data.functionid, 'GET', this.set_apimanagement_selected_data, {}, {"data": res.DATA, "apimanagement_select_data": OptionData, "functionid": data.functionid})
    }

    //展示接口管理modal
    show_apimanagement_modal = (e, row)=>{
        api_call('/apimanagement/', 'GET', this.set_apimanagement_select_data,{},{"functionid": row.functionid})
        this.setState({"show_apimanagement_modal": true, "show_apimanagement_modal_title": row.functinoname, });
    }
    add_callback = (res, data) => {
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 3000,
            }
        } else {
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 3000,
            }
        }
        $(data.e.target).removeAttr("disabled")
        api_call('/menufunctions', 'GET', this.set_tableData)
        PubSub.publish('AlertNotify', alert_info);
    }

    add = (e) => {
        $(e.target).attr("disabled", "disabled")
        let functionname = $("#functionname").val().trim();
        let description = $("#description").val().trim();
        let menu_id = $("#menu_id").val().trim();
        let data = {"functionname": functionname, "description": description, "menu_id": menu_id};
        api_call('/menufunctions', 'POST', this.add_callback, data, {'e': e});
    }


    del_callback = (res, data) => {
        let alert_info;
        if (res.RTN_CODE === '01') {
            alert_info = {
                "title": "提示",
                "content": res.RTN_MSG,
                "type": "success",
                "position": "top-right",
                "dismiss": 3000,
            }
        } else {
            alert_info = {
                "title": "错误",
                "content": res.RTN_MSG,
                "type": "error",
                "position": "top-right",
                "dismiss": 3000,
            }
        }
        api_call('/menufunctions', 'GET', this.set_tableData)
        PubSub.publish('AlertNotify', alert_info);
    }
    del = (id) => {
        api_call('/menufunctions/del_func', 'POST', this.del_callback, {"functionid": id})
    }


    search = e => {
        let search_field = ['functionname', 'menu_id'];   //定义搜索字段
        let search_data = [];
        search_field.forEach((v, i)=>{
            let search_value = $("#search_" + v).val().trim();
            if (v === 'menu_id' && search_value === '-1')
                return
            if (search_value !== '') {
                search_data.push(v + '=' + search_value);
            }
        })
        if (search_data.length > 0){
            api_call('/menufunctions?' + search_data.join('&'), 'GET', this.set_tableData, search_data)
        }
    }

    render() {
        return (
            <div className='page-inner' data-background-color={this.props.theme.body}>
                <div className="page-header">
                    <h4 className="page-title">{this.state.name}</h4>
                    <ul className="breadcrumbs">
                        <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa-brands fa-dev"/>
                            </span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>开发配置</span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>{this.state.name}</span>
                        </li>
                    </ul>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div  className='row text-left'>
                                <div className='col-md-2'>
                                    <button className='btn btn-primary btn-sm' onClick={e => {
                                        this.show_add_modal(e)
                                    }}><FontAwesomeIcon icon="fa fa-solid fa-plus" size='xl' />
                                    </button>
                                </div>
                                <div className='col-md-2'>
                                    <input type="text" className="form-control form-control-search" id='search_functionname' placeholder="名称"/>
                                </div>
                                <div className='col-md-2'>
                                    <select type="text" className="form-control form-control-search" id='search_menu_id' placeholder="菜单">
                                        <option value='-1'>请选择菜单</option>
                                        {this.state.menus.map((item, idx)=>{
                                            return <option key={idx} defaultValue={item.link_id} value={item.link_id}>{item.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className='col-md-2 text-left'>
                                    <button className='btn btn-primary btn-sm' onClick={e => {
                                        this.search(e)
                                    }}><FontAwesomeIcon icon="fa fa-magnifying-glass" size='xl' />
                                    </button>
                                    <button className='btn btn-info btn-sm' onClick={e => {
                                        $("#search_functionname").val('')
                                        $("#search_menu_id").val('-1')
                                        api_call('/menufunctions', 'GET', this.set_tableData)
                                    }}>清空搜索
                                    </button>
                                </div>
                            </div>
                            <div className='table-responsive'>
                                <DataTable
                                    columns={this.state.column}
                                    data={this.state.tableData}
                                    selectableRows
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight={$(window).height() + 'px'}
                                    theme=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={this.state.show_modal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.modal_title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group form-floating-label">
                            <input type="text" id='functionname' placeholder='功能名称'
                                   className="form-control input-border-bottom"/>
                        </div>
                        <div className="form-group form-floating-label">
                            <input type="text" id='description' placeholder='功能描述'
                                   className="form-control input-border-bottom"/>
                        </div>
                        <div className="form-group form-floating-label">
                            <select className="selectpicker form-control input-border-bottom" id='menu_id'>
                                {this.state.menus.map((item, idx)=>{
                                    return <option key={idx} value={item.link_id}>{item.name}</option>
                                })}
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-danger' size='sm' onClick={this.close_modal}>关闭</Button>
                        <Button size='sm' onClick={e => {
                            this.state.modal_submit_callback(e)
                        }}>提交</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={this.state.show_apimanagement_modal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.state.show_apimanagement_modal_title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Select
                            options={this.state.apimanagement_select_data}
                            isMulti
                            placeholder='请选择要绑定的接口'
                            name="colors"
                            className="basic-multi-select api-bind-select"
                            classNamePrefix="select"
                            onChange={e=>{this.api_select_change(e);}}
                            style={{"zIndex": '9999'}}
                          />
                        <DataTable
                                    columns={this.state.apimanagement_column}
                                    data={this.state.apimanagement_data}
                                    selectableRows
                                    pagination
                                    fixedHeader
                                    paginationComponentOptions = {{
                                        rowsPerPageText:'每页行数:',
                                        rangeSeparatorText: '/',
                                        selectAllRowsItem: true,
                                        selectAllRowsItemText: '全部'
                                    }}
                                    fixedHeaderScrollHeight={$(window).height() + 'px'}
                                    theme=""
                                />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='btn-danger' size='sm' onClick={e=>{this.setState({"show_apimanagement_modal": false})}}>关闭</Button>
                        <Button size='sm' onClick={e => {
                            this.submit_bind_api(e)
                        }}>绑定</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default MenuFunctions;
