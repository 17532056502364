import React from "react";
import DataTable from 'react-data-table-component';
import $ from 'jquery';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import PubSub from 'pubsub-js';
import Button from 'react-bootstrap/Button';
import TableSearchFields from '../../../../components/TableSearchFields/TableSearchFields';
import DynamicModal from '../../../../components/DynamicModal/DynamicModal';
import {http_call} from '../../../../libs/utils';
import {confirm} from 'react-bootstrap-confirmation';



class SystemUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "name": '系统用户管理',
            "column": [
                {
                    "name": "操作",
                    "width": "250px",
                    "selector": row => {
                        return <div style={{"minWidth": "150px"}}><FontAwesomeIcon title='删除' icon="fa fa-trash"
                                                                                   size='xl'
                                                                                   style={{
                                                                                       'color': 'red',
                                                                                       "cursor": "pointer"
                                                                                   }} onClick={e => {
                            this.del(row.userid)
                        }}/>
                            <FontAwesomeIcon title='编辑' icon="fa fa-pen-to-square"
                                             style={{'color': '#d38269', "cursor": "pointer", "marginLeft": '5px'}}
                                             size='xl' onClick={e => {
                                this.edit_user(row.userid)
                            }}/>
                            <FontAwesomeIcon title='解除登陆限制' icon="fa fa-unlock"
                                             style={{'color': 'green', "cursor": "pointer", "marginLeft": '5px'}}
                                             size='xl' onClick={e => {
                                this.change_status(row.userid, '2')
                            }}/>
                            <Button onClick={e => {
                                let status = 1;
                                if (row.is_active)
                                    status = 0
                                else
                                    status = 1
                                this.change_status(row.userid, status)
                            }} className={row.is_active === 0 ? 'btn btn-xs btn-info' : 'btn btn-xs btn-danger'}
                                    style={{"marginLeft": "5px"}}>{row.is_active === 0 ? '启用' : '禁用'}</Button>
                        </div>
                    }
                },
                {
                    "name": "ID",
                    "selector": row => row.userid,
                },
                {
                    "name": "用户名",
                    "selector": row => {
                        // let edit_id = 'username'
                        return <input readOnly defaultValue={row.username} onClick={e => {
                        }} onBlur={e => {
                        }} className="edit_input"/>
                    },
                },
                {
                    "name": "姓名",
                    "selector": row => {
                        // let edit_id = 'name'
                        return <input readOnly defaultValue={row.name} onClick={e => {
                        }} onBlur={e => {
                        }} className="edit_input"/>
                    },
                },
                {
                    "name": "状态",
                    "selector": row => {
                        if (row.is_active === 0)
                            return <span style={{"color": "red"}}>禁用</span>
                        return <span style={{"color": "green"}}>活动</span>
                    },
                },
                {
                    "name": "角色",
                    "selector": row => row.rolename
                },
                {
                    "name": "创建时间",
                    "selector": row => {
                        return <input readOnly defaultValue={row.create_time} className="edit_input"/>
                    },
                },
                {
                    "name": "创建用户",
                    "selector": row => {
                        return <input readOnly defaultValue={row.create_user} className="edit_input"/>
                    },
                },
                {
                    "name": "上次登陆时间",
                    "selector": row => {
                        return <input readOnly defaultValue={row.last_login_time} className="edit_input"/>
                    },
                }
            ],
            "modal_title": "",
            "moda_callback": "",
            "roleid_select_options": [],
            "search_rule": [
                {
                            "label_name": "姓名",
                            "type": "text",
                            "id": "search_name",
                            "value": "",
                            "must": false,
                            "nullable": false,
                         },{
                            "label_name": "用户名",
                            "type": "text",
                            "id": "search_username",
                            "value": "",
                            "must": false,
                            "nullable": false,
                         },
                            {
                            "label_name": "活动状态",
                            "type": "select",
                            "id": "is_active",
                            "disable": false,
                            "value": "",
                            "must": true,
                            "nullable": false,
                            "return_field": 'value',
                            "optionData":[{"value": "", "label": "请选择"}, {"value": "0", "label": "禁用"}, {"value": "1", "label": "活动"}]
                         }],
            "selectRows": {},
            "change_password_show": false,
            "change_password_rule": [
                {
                    "label_name": '用户名',
                    "type": "hidden",
                    "id": "password_userid",
                    "value": '',
                    "must": true,
                    "nullable": false,
                    "disable": true,
                },
                {
                    "label_name": '新密码',
                    "placeholder": '新密码',
                    "type": "password",
                    "id": "new_password",
                    "value": '',
                    "must": true,
                    "nullable": false,
                    "disable": false,
                },
                {
                    "label_name": "重复新密码",
                    "type": "password",
                    "placeholder": '重复新密码',
                    "id": "password",
                    "value": "",
                    "must": true,
                    "nullable": false,
                    "onchange": (e)=>{
                        if(e.target.value !== $("#new_password").val()){
                            let alert_info = {
                            "title": "提示",
                            "content": '两次密码不匹配',
                            "type": "error",
                            "position": "top-right",
                            "dismiss": 1000,
                        }
                        PubSub.publish('AlertNotify', alert_info);
                        }
                    },
                    "check_parameters": (all_val, val)=>{
                        if (all_val.new_password !== val) {
                            let alert_info = {
                                "title": "提示",
                                "content": '两次密码不匹配',
                                "type": "error",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                            PubSub.publish('AlertNotify', alert_info);
                            return false;
                        }
                        return true;
                    }
                },
            ],
        }
    }
    change_status = (userid, status) => {
        http_call('/users/user_status', 'POST', {"userid": userid, "status": status}).then(res => {
            let alert_info;
            if (res.RTN_CODE === '00' || res.RTN_CODE === '03' || res.RTN_CODE === '04') {
                alert_info = {
                    "title": "提示",
                    "content": res.RTN_MSG,
                    "type": "success",
                    "position": "top-right",
                    "dismiss": 1000,
                }
                this.refresh_data()
            } else {
                alert_info = {
                    "title": "提示",
                    "content": res.RTN_MSG,
                    "type": "error",
                    "position": "top-right",
                    "dismiss": 1000,
                }
            }
            PubSub.publish('AlertNotify', alert_info);
        }).catch((res) => {
            console.log(res)
        })
    }
    refresh_data = () => {
        http_call('/users', 'GET').then(res => {
            this.setState({"tableData": res.DATA})
        }).catch((res) => {
            console.log(res)
        })
    }
    componentDidMount() {
        http_call('/roles:roleid,rolename', "GET").then(res => {
            res = res.DATA;
            let tmp_list = []
            res.forEach(item => {
                tmp_list.push({"name": item.rolename, "value": item.roleid})
            })
            this.setState({"roleid_select_options": tmp_list})
        })
        this.refresh_data()
    }
    del = async userid => {
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
            {
                "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
                "okText": "确认",
                okButtonStyle: 'warning',
                "cancelText": "取消"
            });
        if (result) {
            http_call("/users/del_user", 'POST', {"userid": userid}).then(res => {
                let alert_info;
                if (res.RTN_CODE === '01') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    this.refresh_data()
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }
    create_user = (e) => {
        let data = {};
        for (let i = 0; i < this.state.modal_input.length; i++) {
            let item = this.state.modal_input[i];
            let val = $("#" + item.id).val().trim();
            if (item.nullable !== undefined && !item.nullable) {
                if (val === '') {
                    let alert_info = {
                        "title": "提示",
                        "content": item.label_name + '不可为空',
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    PubSub.publish('AlertNotify', alert_info);
                    break
                }
            }
            if (item.must) {
                data[item.id] = val
            }
        }
        http_call("/users", 'POST', data).then(res => {
            let alert_info;
            if (res.RTN_CODE === '01') {
                alert_info = {
                    "title": "提示",
                    "content": res.RTN_MSG,
                    "type": "success",
                    "position": "top-right",
                    "dismiss": 1000,
                }
                this.refresh_data()
            } else {
                alert_info = {
                    "title": "提示",
                    "content": res.RTN_MSG,
                    "type": "error",
                    "position": "top-right",
                    "dismiss": 1000,
                }
            }
            PubSub.publish('AlertNotify', alert_info);
        })
    }
    post_edit = (e)=>{
        let data = {};
        let flag = true;
        for (let i = 0; i < this.state.modal_input.length; i++) {
            let item = this.state.modal_input[i];
            let val = $("#" + item.id).val().trim();
            if (item.nullable !== undefined && !item.nullable) {
                if (val === '') {
                    let alert_info = {
                        "title": "提示",
                        "content": item.label_name + '不可为空',
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    PubSub.publish('AlertNotify', alert_info);
                    flag = false
                    break
                }
            }
            if (item.must) {
                data[item.id] = val
            }
        }
        if (flag) {
            data['userid'] = this.state.current_edit_userid
            http_call("/users/edit_user", 'POST', data).then(res => {
                let alert_info;
                if (res.RTN_CODE === '01') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    this.refresh_data()
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }
    edit_user = (userid) => {
        http_call("/users?userid=" + userid, 'GET').then(res => {
            let modal_input = [
                    {
                        "label_name": "姓名",
                        "type": "text",
                        "id": "name",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "角色",
                        "type": "select",
                        "id": "roleid",
                        "value": "",
                        "must": true,
                        "nullable": false,
                        "callback": item => {
                            return <div className="form-group form-floating-label">
                                {item.label_name}
                                <select type={item.type} id={item.id} className="form-control input-border-bottom">
                                    {
                                        this.state[item.id + '_' + item.type + '_options'].map(val => {
                                            if (val.value === item.value)
                                                return <option value={val.value} selected>{val.name}</option>
                                            return <option value={val.value}>{val.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                    }
                ];
            Object.keys(res.DATA[0]).forEach(item => {
                for (let i = 0; i < modal_input.length; i++) {
                    if (item === 'rolename'){
                        this.state.roleid_select_options.forEach(opt=>{
                            if (opt.name === res.DATA[0][item])
                                res.DATA[0]['roleid'] = opt.value
                        })
                        item = 'roleid'
                    }
                    if (modal_input[i].id === item) {
                        modal_input[i].value = res.DATA[0][item]
                    }
                }
            })
            this.setState({
                "modal_input": modal_input,
                "modal_title": "编辑用户: " + res.DATA[0].username,
                "user_modal_show": true,
                "moda_callback": this.post_edit,
                "current_edit_userid": res.DATA[0].userid
            })
        })
    }
    show_add = (e) => {
        this.setState({
            "user_modal_show": true,
            "modal_title": "新增用户",
            "moda_callback": this.create_user,
            "modal_input": [
                {
                    "label_name": "用户名",
                    "type": "text",
                    "id": "username",
                    "value": "",
                    "must": true,
                    "nullable": false,
                },
                {
                    "label_name": "密码",
                    "type": "password",
                    "id": "password",
                    "value": "",
                    "must": true,
                    "nullable": false,
                },
                {
                    "label_name": "姓名",
                    "type": "text",
                    "id": "name",
                    "value": "",
                    "must": true,
                    "nullable": false,
                },
                {
                    "label_name": "角色",
                    "type": "select",
                    "id": "roleid",
                    "value": "",
                    "must": true,
                    "nullable": false,
                    "callback": item => {
                        return <div className="form-group form-floating-label">
                            {item.label_name}
                            <select type={item.type} id={item.id} className="form-control input-border-bottom">
                                {
                                    this.state[item.id + '_' + item.type + '_options'].map(val => {
                                        if (val.value === item.value)
                                            return <option value={val.value} selected>{val.name}</option>
                                        return <option value={val.value}>{val.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    }
                }
            ],
        });
    }

    show_change_password = (e)=>{
        if (this.state.selectRows.selectedCount === 0 || this.state.selectRows.selectedCount === undefined){
            let alert_info = {
                "title": "提示",
                "content": '没有用户被勾选',
                "type": "error",
                "position": "top-right",
                "dismiss": 1000,
            }
            PubSub.publish('AlertNotify', alert_info);
            return
        }
        else if (this.state.selectRows.selectedCount > 1){
            let alert_info = {
                "title": "提示",
                "content": '一次只能勾选一个用户',
                "type": "error",
                "position": "top-right",
                "dismiss": 1000,
            }
            PubSub.publish('AlertNotify', alert_info);
            return;
        }
        this.state.change_password_rule[0].value = this.state.selectRows.selectedRows[0].userid
        this.setState({"change_password_show": true});
    }
    render() {
        return <div className='page-inner'>
            <div className="page-header">
                <h4 className="page-title">{this.state.name}</h4>
                <ul className="breadcrumbs">
                    <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-gear"/>
                            </span>
                    </li>
                    <li className="separator">
                        <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                    </li>
                    <li className="nav-item">
                        <span>系统设置</span>
                    </li>
                    <li className="separator">
                        <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                    </li>
                    <li className="nav-item">
                        <span>{this.state.name}</span>
                    </li>
                </ul>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='card'>
                        <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                            <button className='btn btn-primary btn-xs'
                                    onClick={e => {
                                        this.show_add(e)
                                    }
                                    }>新增用户
                            </button>
                            <button className='btn btn-warning btn-xs'
                                    onClick={e => {
                                        this.show_change_password(e)
                                    }
                                    }>修改密码
                            </button>
                            <TableSearchFields rule={this.state.search_rule} onSearch={(data)=>{
                                if (data['search_name'] !== undefined) {
                                    data['name'] = data['search_name']
                                    data['username'] = data['search_username']
                                    delete data['search_name']
                                    delete data['search_username']
                                }
                                http_call('/users', 'GET', data).then(res=>{
                                     this.setState({"tableData": res.DATA})
                                })
                            }}/>
                        </div>
                        <DataTable
                        noDataComponent=<h1 style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>
                        columns={this.state.column}
                        data={this.state.tableData}
                        selectableRows
                        progressPending={this.state.pending}
                        pagination
                        fixedHeader
                        fixedHeaderScrollHeight={$(window).height() + 'px'}
                        theme=""
                        onSelectedRowsChange={(rows)=>{
                            this.setState({"selectRows": rows})
                        }}
                        />
                    </div>
                </div>
            </div>
            { this.state.change_password_show ?
            <DynamicModal show={this.state.change_password_show}
                          rules={this.state.change_password_rule}
                          title='修改用户密码'
                          submit_title="确认修改"
                          close_callback={e=>{
                              this.setState({"change_password_show": false})
                          }}
                          submit_callback={(e, data)=>{
                              e.target.disabled = true
                              http_call("/users/chang_password", 'POST', {"password": data['password'], "userid": data['password_userid']}).then(res=>{
                                e.target.disabled = false
                                let alert_info;
                                if (res.RTN_CODE === '01') {
                                    alert_info = {
                                        "title": "提示",
                                        "content": res.RTN_MSG,
                                        "type": "success",
                                        "position": "top-center",
                                        "dismiss": 1000,
                                    }
                                    this.setState({"change_password_show": false})
                                }
                                else {
                                    alert_info = {
                                        "title": "提示",
                                        "content": res.RTN_MSG,
                                        "type": "warning",
                                        "position": "top-center",
                                        "dismiss": 1000,
                                    }
                                }
                                PubSub.publish('AlertNotify', alert_info);
                              }).catch(res=>{
                                  e.target.disabled = false
                              })
                          }}
            />
                : null
            }
            {this.state.modal_input === undefined ? null :
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                show={this.state.user_modal_show}
            >
                <Modal.Header>
                    <Modal.Title>
                        {this.state.modal_title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.modal_input.map(item => {
                            if (typeof (item.callback) === 'function') {
                                return item.callback(item)
                            }
                            return <div key={item.id} className="form-group form-floating-label">
                                <input type={item.type} id={item.id} placeholder={item.label_name}
                                       defaultValue={item.value} disabled={item.disable ? 'true' : ''}
                                       className="form-control input-border-bottom"/>
                            </div>
                        })
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-danger'
                            onClick={e => {
                                this.setState({"user_modal_show": false});
                            }
                            }
                            size='sm'>关闭</Button>
                    <Button onClick={e => {
                        this.state.moda_callback(e)
                    }} size='sm'>提交</Button>
                </Modal.Footer>
            </Modal>
            }
        </div>
    }
}

export default SystemUser;
