import React from "react";
import './css/style.css';
import Footer from '../../footer/Footer';
import SystemUser from './SystemUser/SystemUser';
import SystemMenus from './SystemMenus/SystemMenus';
import ApiDocs from './ApiDocs/ApiDocs';
import ApiManagement from './ApiManagement/ApiManagement';
import MenuFunctions from './MenuFunctions/MenuFunctions';
import SystemRole from './SystemRole/SystemRole';
import AreaManagement from './Business/AreaManagement/AreaManagement';
import LobbyManagement from './Business/LobbyManagement/LobbyManagement';
import AgentManagement from './Business/AgentManagement/AgentManagement';
import DepartmentManagement from './Business/DepartmentManagement/DepartmentManagement';
import ItemType from './Business/ItemType/ItemType';
import Items from './Business/Items/Items';
import SubItems from './Business/SubItems/SubItems';
import ItemsWcb from './Business/ItemsWcb/ItemsWcb';
import SubItemsWcb from './Business/SubItemsWcb/SubItemsWcb';
import TopicManagement from "./Business/TopicManagement/TopicManagement"
import Reservation from "./Business/Reservation/Reservation"

class MainContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "Dashboard": <h1 className='text-center'>This is Dashboard</h1>,
            "SystemUsers": <SystemUser appProps={props}/>,
            "SystemMenus": <SystemMenus appProps={props} theme={this.props.theme.body}/>,
            "ApiDocs": <ApiDocs appProps={props} theme={this.props.theme.body}/>,
            "MenuFunctions": <MenuFunctions appProps={props} theme={this.props.theme.body}/>,
            "ApiManagement": <ApiManagement appProps={props} theme={this.props.theme.body}/>,
            "SystemRole": <SystemRole appProps={props} theme={this.props.theme.body}/>,
            "AreaManagement": <AreaManagement appProps={props} theme={this.props.theme.body}/>,
            "LobbyManagement": <LobbyManagement appProps={props} theme={this.props.theme.body}/>,
            "AgentManagement": <AgentManagement appProps={props} theme={this.props.theme.body}/>,
            "DepartmentManagement": <DepartmentManagement appProps={props} theme={this.props.theme.body}/>,
            "ItemType": <ItemType appProps={props} theme={this.props.theme.body}/>,
            "Items": <Items appProps={props} theme={this.props.theme.body}/>,
            "SubItems": <SubItems appProps={props} theme={this.props.theme.body}/>,
            "TopicManagement": <TopicManagement appProps={props} theme={this.props.theme.body}/>,
            "Reservation": <Reservation appProps={props} theme={this.props.theme.body}/>,
            "ItemsWcb": <ItemsWcb appProps={props} theme={this.props.theme.body}/>,
            "SubItemsWcb": <SubItemsWcb appProps={props} theme={this.props.theme.body}/>,
        }
    }

    no_found = (path)=>{
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title text-center">404 No Found</div>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-center p-3">
                                <div id="lineChart">
                                    <canvas width="250" height="100"
                                            style={{"display": "inline-block",
                                                "width": "250px",
                                                "height": "100px",
                                                "vertical-align": "top"}}></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        let grant_menus = window.localStorage.getItem("grant_menus")
        if (grant_menus === null){
            grant_menus = []
        }
        else{grant_menus = JSON.parse(grant_menus);}
        if (grant_menus.indexOf(this.props.current_MainContent) === -1 && this.props.current_MainContent !== 'Dashboard') return this.props.set_status('404')
        return (
            <div className="main-panel" data-background-color={this.props.theme.body}>
                {Object.keys(this.state).indexOf(this.props.current_MainContent) === -1 ?
                    this.props.set_status('404') : this.state[this.props.current_MainContent]}
                <Footer/>
            </div>
        )
    }
}

export default MainContent;