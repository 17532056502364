import React from "react";
// import Dropdown from 'react-bootstrap/Dropdown';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {library} from '@fortawesome/fontawesome-svg-core'
// import {fas} from '@fortawesome/free-solid-svg-icons'
// library.add(fas);

function Profile(props) {
    let img = props.img_src || '';
    let text = props.text || 'Admin';
    return (
        <li className="nav-item dropdown hidden-caret">
            <a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="void(0)"
               aria-expanded="false">
                <div className="avatar-sm">
                    {img ? <img src="../assets/img/profile.jpg" alt="..."
                                className="avatar-img rounded-circle" />  : <h3 className='text-cetner' style={{"color": "white", "paddingTop": "5px"}}>{text}</h3>}
                </div>
            </a>
            <ul className="dropdown-menu dropdown-user animated fadeIn">
                <div className="scroll-wrapper dropdown-user-scroll scrollbar-outer"
                     style={{"position": "relative"}}>
                    <div className="dropdown-user-scroll scrollbar-outer scroll-content"
                         style={{"height": "auto", "marginBottom": "0px", "marginRight": "0px", "maxHeight": "0px"}}>
                        <li>
                            <div className="user-box">
                                <div className="avatar-lg">
                                    <img alt="nope" className="avatar-img rounded"/></div>
                                <div className="u-text">
                                    <h4>Hizrian</h4>
                                    <p className="text-muted">hello@example.com</p><a
                                    href="profile.html" className="btn btn-xs btn-secondary btn-sm">View
                                    Profile</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/#">My Profile</a>
                            <a className="dropdown-item" href="/#">My Balance</a>
                            <a className="dropdown-item" href="/#">Inbox</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/#">Account Setting</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="/#">Logout</a>
                        </li>
                    </div>
                    <div className="scroll-element scroll-x">
                        <div className="scroll-element_outer">
                            <div className="scroll-element_size"></div>
                            <div className="scroll-element_track"></div>
                            <div className="scroll-bar ui-draggable ui-draggable-handle"></div>
                        </div>
                    </div>
                    <div className="scroll-element scroll-y">
                        <div className="scroll-element_outer">
                            <div className="scroll-element_size"></div>
                            <div className="scroll-element_track"></div>
                            <div className="scroll-bar ui-draggable ui-draggable-handle"></div>
                        </div>
                    </div>
                </div>
            </ul>
        </li>
    )
}

export default Profile;