import React from "react";
import $ from 'jquery';
import {http_call} from '../../libs/utils.js';
import PubSub from 'pubsub-js';


class PrivilegesGrant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "checkData": [],
            "checked": []
        }
    }

    componentDidMount() {
        let url = '/roles/get_privileges_function_list';
        if (this.props.current_roleid !== undefined) {
            url = '/roles/get_privileges_function_list?roleid=' + this.props.current_roleid;
        }
        http_call(url, 'GET', ).then((res) => {
            this.setState({"checkData": res.DATA})
            return res.GRANTED;
            }).then(res=> {
                setTimeout(()=>{
                    let granted = [];
                    res.forEach(item => {
                        let target = $($("input[funcid='" + item + "']")[0])
                        granted.push(target.val())
                        target.prop('checked', true)
                        let parent_name = target.attr('parent_name')
                        let parent_parent_name = $($("input[value='" + parent_name + "']")[0]).attr('parent_name')
                        $($("input[value='" + parent_name + "']")[0]).prop('checked', true)
                        $($("input[parent_name='" + parent_parent_name + "']")[0]).prop('checked', true);
                        this.get_checkbox_val()
                    })
                    this.setState({"Granted": granted})     // 已经授权功能
                }, 1000)
            }
        ).catch((res) => {
            console.log(res)
        })
    }

    // 获取选中的值
    get_checkbox_val = () => {
        let val = [];
        $("input[name='func']:checked").each((idx, item) => {
            val.push($(item).val())
        })
        this.setState({"checked_func_privileges": val.toString()});
        this.props.setData(val.toString());
    }

    update_privilege = (roleid, privileges, check_status)=>{
        let data = {"roleid": roleid}
        if (check_status){
            data['add_privileges'] = privileges;
        }
        else{
            data['del_privileges'] = privileges;
        }
        http_call('/roles/update_role', 'POST', data).then((res) => {
                let alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                PubSub.publish('AlertNotify', alert_info);
            }).catch((res) => {
            console.log(res)
        })
    }

    // 功能
    gen_functions = (data, pid) => {
        return <label className="selectgroup-item">
            <input type="checkbox" name="func" funcid={data.functionid} all_name='checkall' parent_name={'menu_' + pid}
                   value={pid.toString() + '-' + data.functionid.toString()}
                   className="selectgroup-input" onChange={(event) => {
                        const target = event.target;
                        const value = target.type === 'checkbox' ? target.checked : target.value;
                        let parent_name = 'menu_' + pid;
                        let parent_parent_name = $($("input[value='" + parent_name + "']")[0]).attr('parent_name')
                        if (value) { //勾选功能
                            $($("input[value='" + parent_name + "']")[0]).prop('checked', true)
                            $($("input[parent_name='" + parent_parent_name + "']")[0]).prop('checked', true);
                            if ($("input[all_name='checkall']:checked").length === $("input[all_name='checkall']").length) {
                                $("#check-all").prop("checked", true)
                            }
                        } else {
                            // 判断是否第功能被选中
                            if ($("input[parent_name='" + parent_name + "'][name='func']:checked").length === 0) {
                                $($("input[value='" + parent_name + "']")[0]).prop('checked', false)
                            }
                            if ($("input[parent_name='" + parent_parent_name + "'][name='check_menu']").length > 1 && $("input[parent_name='" + parent_parent_name + "'][name='check_menu']:checked").length === 1) {
                                $($("input[value='" + parent_parent_name + "']")[0]).prop('checked', false)
                            }
                            $("#check-all").prop("checked", false)
                        }
                        this.get_checkbox_val();
                        // 编辑 实时更新
                        if (this.props.current_roleid !== undefined){
                            this.update_privilege(this.props.current_roleid, $(target).val() , value)
                        }
            }}
            />
            <span className="selectgroup-button">{data.functionname}</span>
        </label>
    }

    gen_privileges_table = (data) => {
        return <div>
            <tr style={{"borderBottomWidth": '0'}}>
                <td style={{"minWidth": "150px"}}>
                    <div className='form-check'>
                        <label className="form-check-label">
                            <input className="form-check-input" name='check_menu' all_name='checkall'
                                   parent_name={'menu_' + data.pid} type="checkbox" dataid={data.id} disabled={this.props.current_roleid === undefined ? false : true}
                                   value={'menu_' + data.id} onChange={
                                event => {
                                    if (data.pid === data.id) {     // 顶级菜单
                                        let sub_func_name = 'menu_' + data.pid;
                                        const target = event.target;
                                        const value = target.type === 'checkbox' ? target.checked : target.value;
                                        if (value) {
                                            $('input[parent_name=' + sub_func_name + ']').each((idx, item) => {
                                                $(item).prop("checked", true);
                                                // 下级功能勾选
                                                let func_parent_name = $(item).val();
                                                $('input[parent_name=' + func_parent_name + ']').each((idx, func_item) => {
                                                    $(func_item).prop("checked", true)
                                                })
                                            })
                                            // 全选检测
                                            if ($("input[all_name='checkall']:checked").length === $("input[all_name='checkall']").length) {
                                                $("#check-all").prop("checked", true)
                                            }

                                        } else {
                                            //全选去掉
                                            $("#check-all").prop("checked", false)
                                            $('input[parent_name=' + sub_func_name + ']').each((idx, item) => {
                                                $(item).prop("checked", false);
                                                let func_parent_name = $(item).val();
                                                $('input[parent_name=' + func_parent_name + ']').each((idx, func_item) => {
                                                    $(func_item).prop("checked", false)
                                                })
                                            })
                                        }
                                    } else { //子菜单
                                        //向下勾选
                                        let sub_func_name = 'menu_' + data.id;
                                        const target = event.target;
                                        const value = target.type === 'checkbox' ? target.checked : target.value;
                                        if (value) {
                                            $('input[parent_name=' + sub_func_name + ']').each((idx, item) => {
                                                $(item).prop("checked", true);
                                            })
                                            //勾选上级
                                            $($('input[value=menu_' + data.pid + ']')[0]).prop("checked", true)
                                            // 勾选全部功能
                                            // 全选检测
                                            if ($("input[all_name='checkall']:checked").length === $("input[all_name='checkall']").length) {
                                                $("#check-all").prop("checked", true)
                                            }
                                        } else {
                                            $('input[parent_name=' + sub_func_name + ']').each((idx, item) => {
                                                $(item).prop("checked", false)
                                            })
                                            // 检测是否是最后一个勾选
                                            sub_func_name = 'menu_' + data.pid;
                                            let all_checked_sub_func_name = $('input[parent_name=' + sub_func_name + ']:checked');
                                            if (all_checked_sub_func_name.length === 1) {// 只剩下一个父节点处于勾选
                                                $($('input[parent_name=' + sub_func_name + ']')[0]).prop("checked", false)
                                            }
                                            //全选去掉
                                            $("#check-all").prop("checked", false)
                                        }
                                    }
                                    this.get_checkbox_val()
                                }
                            }/>
                            <span className="form-check-sign">{data.name}</span>
                        </label>
                    </div>
                    {/*<tr>*/}
                        <div className='selectgroup-pills'>
                            {
                                data.functions.map(item => {
                                    return this.gen_functions(item, data.id)
                                })
                            }
                        </div>
                    {/*</tr>*/}
                    {
                        data.children.map(item => {
                            return this.gen_privileges_table(item)
                        })
                    }
                </td>
            </tr>
        </div>
    }

    render() {
        return <div>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>
                        <div className='form-check'>
                            <label className="form-check-label">
                                <input disabled={this.props.current_roleid === undefined ? false : true} className="form-check-input" type="checkbox" id='check-all' onChange={event => {
                                    const target = event.target;
                                    const value = target.type === 'checkbox' ? target.checked : target.value;
                                    if (value) {
                                        $("input[all_name='checkall']").each((idx, item) => {
                                            $(item).prop("checked", true)
                                        })
                                    } else {
                                        $("input[all_name='checkall']").each((idx, item) => {
                                            $(item).prop("checked", false)
                                        })
                                    }
                                    this.get_checkbox_val()
                                }
                                }/>
                                <span className="form-check-sign">全选</span>
                            </label>
                        </div>
                    </th>
                </tr>
                </thead>
                {
                    this.state.checkData.map(item => {
                        return this.gen_privileges_table(item)
                    })
                }
            </table>
        </div>
    }
}

export default PrivilegesGrant;