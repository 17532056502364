import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call, func_privileges} from '../../../../../libs/utils';
import './css/style.css';
import PubSub from 'pubsub-js';
import {confirm} from 'react-bootstrap-confirmation';




export default class AgentManagement extends Component {
    constructor(props) {
        let func = {"add": 132, 'del': 135, 'edit': 134, 'get': 133}
        func = func_privileges(func)
        super(props);
        this.state = {
            "func": func,
            name: "帮代办人员管理",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            column: [
                {
                    "name": "ID",
                    "selector": row => row.id,
                },
                {
                    "name": "大厅名称",
                    "selector": row => row.dtmc
                },
                {
                    "name": "姓名",
                    "selector": row => row.xm
                },
                {
                    "name": "性别",
                    "selector": row => row.xb === 0 ? "女" : "男"
                },
                {
                    "name": "政治面貌",
                    "selector": row => row.zzmm
                },
                {
                    "name": "岗位职务",
                    "selector": row => row.gwzw
                },
                {
                    "name": "联系电话",
                    "selector": row => row.lxdh
                },
                {
                    "name": "备注",
                    "selector": row => row.bz,
                    maxWidth: '600px',
                    whiteSpace: 'break-spaces',
                },
                {
                    "name": "操作",
                    "width": "100px",
                    "selector": row => {
                        return <div style={{"minWidth": "150px"}}>
                            {this.state.func.del ? <FontAwesomeIcon title='删除' icon="fa fa-trash"
                                             size='xl'
                                             style={{
                                                 'color': 'red',
                                                 "cursor": "pointer"
                                             }} onClick={e => {
                                this.del_bdbrygl(row.id)
                            }}/> : null}
                            {this.state.func.edit ? <FontAwesomeIcon title='编辑' icon="fa fa-pen-to-square"
                                             size='xl'
                                             style={{
                                                 'color': '#d38269',
                                                 "cursor": "pointer",
                                                 "marginLeft": '5px'
                                             }} onClick={e => {
                                this.edit_bdbrygl(e, row.id)
                            }}/> : null}
                        </div>
                    }
                },

            ],
            "search_rule": [
                {
                    "label_name": "大厅名称",
                    "type": "text",
                    "id": "search_dtmc",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                {
                    "label_name": "姓名",
                    "type": "text",
                    "id": "search_xm",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                {
                    "label_name": "联系电话",
                    "type": "text",
                    "id": "search_lxdh",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
            ],
            selectRows: {},
        }
    }

    componentDidMount() {
        this.get_all_bdbrygl();
        console.log(this.state.func)
    }

    add_bdbrygl = (e) => {
        PubSub.publish('Loding', true);
        let options = [{"value": 0, "label": "无大厅"}]
        http_call('/jzzwdt/lobbies:id,dtmc', 'GET').then(res => {
            res.DATA.forEach((val) => {
                options.push({"value": val.id, "label": val.dtmc})
            })
            PubSub.publish('Loding');
            this.setState({
                "modal_show": true,
                "modal_title": "新增帮代办人员",
                "modal_rules": [
                    {
                        "label_name": "大厅",
                        "type": "select",
                        "id": "dtid",
                        "value": {"value": 0, "label": "无"},
                        "must": true,
                        "nullable": false,
                        "multi": false,
                        "placeholder": "请选择区划",
                        "field": "value",
                        "optionData": options,
                    },
                    {
                        "label_name": "姓名",
                        "type": "text",
                        "id": "xm",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "性别",
                        "type": "select",
                        "id": "xb",
                        "value": {"value": 0, "label": "女"},
                        "must": true,
                        "nullable": false,
                        "multi": false,
                        "placeholder": "请选择性别",
                        "field": "value",
                        "optionData": [{"value": 0, "label": "女"}, {"value": 1, "label": "男"}],
                    },
                    {
                        "label_name": "政治面貌",
                        "type": "text",
                        "id": "zzmm",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "岗位职务",
                        "type": "text",
                        "id": "gwzw",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "联系电话",
                        "type": "text",
                        "id": "lxdh",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "备注",
                        "type": "textarea",
                        "id": "bz",
                        "value": "",
                        "must": true,
                        "nullable": true,
                    },
                ],
                "modal_submit_title": "提交",
                "modal_submit_callback": (e, data) => {
                    http_call('/jzzwdt/assistantagent', 'POST', data).then(res => {
                        let alert_info;
                        if (res.RTN_CODE === '01') {
                            alert_info = {
                                "title": "提示",
                                "content": res.RTN_MSG,
                                "type": "success",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                            this.setState({"modal_show": false})
                            this.get_all_bdbrygl()
                        } else {
                            alert_info = {
                                "title": "提示",
                                "content": res.RTN_MSG,
                                "type": "error",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                        }
                        PubSub.publish('AlertNotify', alert_info);
                    })
                },
            });
        })
    }

    edit_bdbrygl= (e, id) => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/assistantagent', 'GET', {'id': id}).then(res => {
            let options = [{"value": 0, "label": "无大厅"}]
            let option = {"value": 0, "label": "无大厅"}
            http_call('/jzzwdt/lobbies:id,dtmc', 'GET').then(res_1 => {
                res_1.DATA.forEach((val) => {
                    options.push({"value": val.id, "label": val.dtmc});
                    if (val.id === res.DATA[0].dtid) {
                        option = {"value": val.id, "label": val.dtmc};
                    }
                })
                PubSub.publish('Loding');
                this.setState({
                "modal_show": true,
                "modal_title": "修改大厅",
                "modal_rules": [
                    {
                        "label_name": "帮代办人id",
                        "type": "hidden",
                        "id": "id",
                        "value": id,
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "大厅",
                        "type": "select",
                        "id": "dtid",
                        "value": option,
                        "must": true,
                        "nullable": false,
                        "multi": false,
                        "placeholder": "请选择区划",
                        "field": "value",
                        "optionData": options,
                    },
                    {
                        "label_name": "姓名",
                        "type": "text",
                        "id": "xm",
                        "value": res.DATA[0].xm,
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "性别",
                        "type": "select",
                        "id": "xb",
                        "value": {"value": res.DATA[0].xb, "label": res.DATA[0].xb === 0 ? "女" : "男"},
                        "must": true,
                        "nullable": false,
                        "multi": false,
                        "placeholder": "请选择性别",
                        "field": "value",
                        "optionData": [{"value": 0, "label": "女"}, {"value": 1, "label": "男"}],
                    },
                    {
                        "label_name": "政治面貌",
                        "type": "text",
                        "id": "zzmm",
                        "value": res.DATA[0].zzmm,
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "岗位职务",
                        "type": "text",
                        "id": "gwzw",
                        "value": res.DATA[0].gwzw,
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "联系电话",
                        "type": "text",
                        "id": "lxdh",
                        "value": res.DATA[0].lxdh,
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "备注",
                        "type": "textarea",
                        "id": "bz",
                        "value": res.DATA[0].bz,
                        "must": true,
                        "nullable": true,
                    },
                ],
                "modal_submit_title": "提交",
                "modal_submit_callback": (e, data) => {
                    http_call('/jzzwdt/assistantagent/edit', 'POST', data).then(res => {
                        let alert_info;
                        if (res.RTN_CODE === '01') {
                            alert_info = {
                                "title": "提示",
                                "content": res.RTN_MSG,
                                "type": "success",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                            this.setState({"modal_show": false})
                            this.get_all_bdbrygl()
                        } else {
                            alert_info = {
                                "title": "提示",
                                "content": res.RTN_MSG,
                                "type": "error",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                        }
                        PubSub.publish('AlertNotify', alert_info);
                    })
                },
            });
            })
        })
    }

    del_bdbrygl= async (id) => {
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
            {
                "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
                "okText": "确认",
                okButtonStyle: 'warning',
                "cancelText": "取消"
            });
        if (result) {
            http_call('/jzzwdt/assistantagent/delete', 'POST', {'id': id}).then(res => {
                let alert_info;
                if (res.RTN_CODE === '01') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    this.setState({"modal_show": false})
                    this.get_all_bdbrygl()
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }

    get_all_bdbrygl = () => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/assistantagent', 'GET').then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    inquiry_bdbrygl = (data) => {
        let DATA = {}
        PubSub.publish('Loding', true);
        Object.keys(data).forEach((key)=>{
            DATA[key.replace('search_', '')] = data[key];
        })
        http_call('/jzzwdt/assistantagent', 'GET', DATA).then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    render() {
        return (
            <div className='page-inner'>
                <div className="page-header">
                    <h4 className="page-title">{this.state.name}</h4>
                    <ul className="breadcrumbs">
                        <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>{this.state.name}</span>
                        </li>
                    </ul>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                {this.state.func.add ? <button className='btn btn-primary btn-xs'
                                        onClick={e => {
                                            this.add_bdbrygl(e);
                                        }
                                        }>新增帮代办人
                                </button> : null }
                                <TableSearchFields rule={this.state.search_rule} onSearch={(data) => {this.inquiry_bdbrygl(data)}}/>
                            </div>
                            <DataTable
                                noDataComponent=<h1
                                style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>
                            columns={this.state.column}
                            data={this.state.tableData}
                            selectableRows
                            progressPending={this.state.pending}
                            pagination
                            paginationComponentOptions = {{
                                rowsPerPageText:'每页行数:',
                                rangeSeparatorText: '/',
                                selectAllRowsItem: true,
                                selectAllRowsItemText: '全部'
                            }}
                            fixedHeader
                            fixedHeaderScrollHeight={"100vh"}
                            theme=""
                            onSelectedRowsChange={(rows) => {
                            this.setState({"selectRows": rows})
                        }}
                            />
                        </div>
                    </div>
                </div>
                {this.state.modal_show ?
                    <DynamicModal show={this.state.modal_show}
                                  rules={this.state.modal_rules}
                                  title={this.state.modal_title}
                                  submit_title={this.state.modal_submit_title}
                                  close_callback={e => {
                                      this.setState({"modal_show": false})
                                  }}
                                  submit_callback={this.state.modal_submit_callback}
                    />
                    : null
                }
            </div>
        );
    }
}
