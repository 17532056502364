import config from '../config.js';
import Base64 from 'base-64';
import PubSub from 'pubsub-js';


export function func_privileges(func_ids) {
    // 数据结构  {"功能名称": 功能ID, 'del': 109, 'edit': 110, 'get': 111}
    let func_privileges = JSON.parse(window.localStorage.getItem('func_privilege_id'))
    let func = {}
    Object.keys(func_ids).forEach(val => {
        if (func_privileges.is_super) {
            func[val] = true
        }
        else{
            if (func_privileges.func_privilege_id.indexOf(func_ids[val]) !== -1){
                func[val] = true
            }
            else{
                func[val] = false
            }
        }
    })
    return func;
}

export function is_token_expire(props) {
    let accessToken = window.localStorage.getItem('accessToken');
    if (accessToken === null || accessToken === '') {
        return true;
    }
    let payload = accessToken.split('.')[1]
    payload = JSON.parse(Base64.decode(payload))
    let current_timestamp = Date.parse(new Date()) / 1000;
    if (current_timestamp > payload.exp) {
        window.location.href = '/login'
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('jwt_info');
        window.localStorage.setItem('forward', window.location.pathname.replace('/', ''))
        window.localStorage.setItem('logout_notify', '登陆过期，请重新登陆')
    }
    return false
}


export function logout(props) {
    fetch(config.api_url + "/login/logout", {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': window.localStorage.getItem('accessToken')
        },
        method: "GET",
    }).then(res => {
        return res.json()
    }).then(res => {
        if (res.RTN_CODE === '01') {
            window.localStorage.removeItem('forward')
            window.location.href = '/'
        }
    })
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('jwt_info');
}

export function api_call(url, type, callback, data = {}, callbakc_data = {}) {
    let fetch_header = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': window.localStorage.getItem('accessToken')
        },
        method: type,
    }
    if (type !== 'GET')
        if (type !== 'HEAD')
            fetch_header['body'] = JSON.stringify(data)
    fetch(config.api_url + url, fetch_header).then(res => {
        return res.json()
    }).then(res => {
            if (res.RTN_CODE === '4037') {
                if (!is_token_expire) {  //没有过期 但是验签失败
                    window.localStorage.setItem('logout_notify', '检测到账号在别处登陆，已自动注销')
                }
                // window.location.href = '/'
                PubSub.publish('AlertNotify', {
                    "title": "会话过期",
                    "content": '请重新登陆',
                    "type": "info",
                    "position": "top-center",
                    "dismiss": '3000',
                });
                window.localStorage.removeItem('accessToken');
                window.localStorage.removeItem('jwt_info');
                return
            }
            callback(res, callbakc_data)
        }
    ).catch((res) => {
        console.log(res)
        PubSub.publish('AlertNotify', {
            "title": "错误",
            "content": '服务器内部错误',
            "type": "error",
            "position": "top-center",
            "dismiss": '',
        });
    })
}


// promise接口调用
export async function http_call(url, method = 'GET', data = null, headers = {}) {
    return await new Promise((resolve, reject) => {
        const options = {
            method: method,
            headers: headers,
        };
        options.headers['Content-Type'] = 'application/json';
        options.headers['Authorization'] = window.localStorage.getItem('accessToken');
        if (data) {
            if (method === 'GET') {
                url += '?' + new URLSearchParams(data).toString();
            } else {
                options.body = JSON.stringify(data);
            }
        }
        fetch(config.api_url + url, options)
            .then(response => {
                if (!response.ok && response.status === 500) {
                    reject(response.json())
                }
                return response.json();
            })
            .then(json => {
                if (json.RTN_CODE === '4037') {
                    // window.location.href = '/'
                    PubSub.publish('AlertNotify', {
                        "title": "会话过期",
                        "content": '请重新登陆',
                        "type": "info",
                        "position": "top-center",
                        "dismiss": '3000',
                    });
                }
                if (json.RTN_CODE === '400') {
                    // window.location.href = '/'
                    PubSub.publish('AlertNotify', {
                        "title": json.RTN_CODE,
                        "content": json.RTN_MSG,
                        "type": "error",
                        "position": "top-center",
                        "dismiss": '6000',
                    });
                    reject(json)
                    return
                }
                resolve(json);
            })
            .catch((res) => {
                PubSub.publish('AlertNotify', {
                    "title": 'Error API',
                    "content": '服务器内部错误',
                    "type": "error",
                    "position": "top-center",
                    "dismiss": '3000',
                });
                reject(res);
            });
    });
}


export default is_token_expire;