import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import './css/style.css'
library.add(fas);

// 通知
function Notify(props) {
    let notity_count = props.notity_count || 0;
    return (
        <Dropdown>
          <Dropdown.Toggle className='nav-link dropdown-toggle' variant="Primary">
            <span className="notification">{notity_count}</span>
            <FontAwesomeIcon style={{'color': 'white'}} size='lg' icon="fa-sharp fa-bell"/>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Header>
                <div className="dropdown-title">您有{notity_count}条通知</div>
            </Dropdown.Header>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Divider></Dropdown.Divider>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
    )
    // return (
    //     <li className="nav-item dropdown hidden-caret submenu show ">
    //         <a className="nav-link dropdown-toggle" href="#" id="notifDropdown" role="button" data-toggle="dropdown"
    //            aria-haspopup="true" aria-expanded="false">
    //             <FontAwesomeIcon size='lg' icon="fa-sharp fa-bell"/>
    //             <span className="notification">{notity_count}</span>
    //         </a>
    //         <ul className="dropdown-menu notif-box animated fadeIn show" aria-labelledby="notifDropdown">
    //             <li>
    //                 <div className="notif-center">
    //                     <a href="#">
    //                         <div className="notif-icon notif-primary"><i className="fa fa-user-plus"></i></div>
    //                         <div className="notif-content">
	// 											<span className="block">
	// 												New user registered
	// 											</span>
    //                             <span className="time">5 minutes ago</span>
    //                         </div>
    //                     </a>
    //                 </div>
    //             </li>
    //             <li>
    //                 <a className="see-all" href="void(0);">See all notifications<i
    //                     className="fa fa-angle-right"></i> </a>
    //             </li>
    //         </ul>
    //     </li>
    // )
}

export default Notify;