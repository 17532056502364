import React from "react";
import $ from 'jquery';
import Menus from './Menus/Menus';
import './css/style.css';
import {logout} from '../../../libs/utils.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

class SideBar extends React.Component{
    constructor(props) {
        super(props);
        let jwt_info = JSON.parse(window.localStorage.getItem("jwt_info"))
        this.state = {
            "username": jwt_info.username || '',
            "rolename": jwt_info.rolename || '',
            "name": jwt_info.name || '',
            "userinfo_collapse": false,
            "pre_click_menu": "",
            "menu_max_height": $(window).height() + 100,
            "startx": 0,
            "endx": 0,
            "pre_click_submenu": "",
        }
    }
    mouseHover = (e)=> {
        if (this.props.wrapClassName === 'main-header' || this.props.wrapClassName === '')
            return;
        if(this.props.wrapClassName.split(' ').indexOf('sidebar_minimize') !== -1)
            return;
        this.props.wrapStats("main-header sidebar_minimize_hover");
    }
    mouseOut = (e)=> {
        if (this.props.wrapClassName === 'main-header')
            return
        if (this.props.wrapClassName.split(' ').indexOf('sidebar_minimize_hover') !== -1){
            this.props.wrapStats("main-header sidebar_minimize")
        }
    }

    //菜单下拉
    dropdown = (e, id) => {
        e.stopPropagation();
        if (this.state.pre_click_menu !== ''){
            if (typeof(this.state.pre_click_menu) === 'object'){
                this.state.pre_click_menu.removeClass('active')
            }
            else{
                $($("#" + this.state.pre_click_menu).parents()[0]).removeClass('active');
            }
        }
        if (id === ''){     // 兼容没有子菜单的菜单
            $($(e.target).parents()[0]).addClass('active')
            this.setState({pre_click_menu: $($(e.target).parents()[0])});
            if (this.state.pre_click_submenu !== '')
            $("#" + this.state.pre_click_submenu).removeClass('active');
            return;
        }
        $($("#" + id).parents()[0]).addClass('active')
        this.setState({pre_click_menu: id});
        // 箭头反转
        if ($($($("#" + id)[0]).siblings()[0]).children('.caret').hasClass('caret_trans')){     //关闭
            $($($("#" + id)[0]).siblings()[0]).children('.caret').removeClass('caret_trans');
            $($("#" + id)[0]).slideToggle();
        }
        else{       // 拉开
            $($($("#" + id)[0]).siblings()[0]).children('.caret').addClass('caret_trans')
            $($("#" + id)[0]).slideToggle();
        }
        if (!this.state[id]) {
            $($("#" + id)[0]).addClass('show');
        }
        else{
            $($("#" + id)[0]).removeClass('show');
        }
        this.setState({[id]: !this.state[id]});
        this.props.wrapStats(this.props.wrapClassName);
    }
    resize_max_sidebar_height = (e) =>{
        this.setState({ menu_max_height: $(window).height() + 100})
    }

    // 触摸开始位置
    startx = (e)=>{
        this.setState({"startx": e.touches[0].pageX});
    }

    // 触摸截止位置
    endx = (e)=>{
        if (!e.touches.length)
            return;
        this.setState({"endx": e.touches[0].pageX});
        let X = this.state.startx - this.state.endx;//左右滑动的距离
        if ( X > 100 ) { //X大于0表示左滑，大于100的原因是为了让页面不会那么敏感
            this.props.wrapStats("main-header sidebar_minimize_hover");
            this.props.set_logo_toggle(false);
         }
         else if ( X < -100 ) {//X小于0表示右滑，小于-100的原因同左滑
         }
    }

    // 设置mainContent 传入组件名称
    set_current_MainContent = (e, MainContentComponent) => {
      e.stopPropagation();
      this.setState({"current_MainContent": MainContentComponent});
      this.props.set_current_MainContent(MainContentComponent);
      if (this.state.pre_click_submenu !== '')
        $("#" + this.state.pre_click_submenu).removeClass('active');
      $("#" + MainContentComponent).addClass('active');
      this.setState({"pre_click_submenu": MainContentComponent});

    }

    componentDidMount(){
        //监听窗口大小改变
        window.addEventListener('resize', this.resize_max_sidebar_height.bind(this));
        window.addEventListener('touchstart', this.startx.bind(this));
        window.addEventListener('touchmove', this.endx.bind(this));
    }
    //移除监听器，防止多个组件之间导致this的指向紊乱
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize_max_sidebar_height.bind(this));
    }

    user_logout = ()=>{
        logout()
    }

    render() {
        return (
            <div className="sidebar sidebar-style-2" data-background-color={this.props.theme.sidebar} onMouseEnter={this.mouseHover} onMouseLeave={this.mouseOut}>
                <div className="scroll-wrapper sidebar-wrapper scrollbar scrollbar-inner" style={{"position": "relative"}}>
                    <div className="sidebar-wrapper scrollbar scrollbar-inner scroll-content"
                         style={{"height": "auto", "marginBottom": "0px", "marginRight": "0px", "maxHeight": this.state.menu_max_height}}>
                        <div className="sidebar-content">
                            <div className="user">
                                <div className="avatar-sm float-left mr-2 text-center" onClick={(e) => this.dropdown(e, 'userinfo_collapse')}>
                                    {/*<img style={{"cursor": "pointer"}} src="/logo192.png" alt="用户头像"*/}
                                    {/*  className="avatar-img rounded-circle"    />*/}
                                    {this.props.theme.sidebar === '' ?
                                        <FontAwesomeIcon  className="avatar-img rounded-circle" style={{"color": "#646464"}} icon="fa fa-user" />
                                        :
                                        <FontAwesomeIcon  className="avatar-img rounded-circle" icon="fa fa-user" />
                                    }
                                </div>
                                <div className="info">
                                    <a href={(e) => this.dropdown(e, 'userinfo_collapse')} style={{"cursor": "pointer"}} onClick={(e) => this.dropdown(e, 'userinfo_collapse')}>
                                        <span style={{"fontWeight": "bold", "textTransform": "capitalize"}}>{this.state.name}</span>
                                        <span className="user-level">{this.state.rolename}</span>
                                        <span className="caret"></span>
                                    </a>
                                    <div className="clearfix"></div>
                                    <div className="collapse in" id="userinfo_collapse">
                                        <ul className="nav">
                                            {/*<li>*/}
                                            {/*    <a >*/}
                                            {/*        <span className="link-collapse">我的信息</span>*/}
                                            {/*    </a>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*    <a >*/}
                                            {/*        <span className="link-collapse">编辑信息</span>*/}
                                            {/*    </a>*/}
                                            {/*</li>*/}
                                            <li onClick={e=>{this.user_logout(e)}}>
                                                <a href="/logout" onClick={e=>{e.preventDefault()}}>
                                                    <span className="link-collapse">注销登录</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <Menus wrapStats={this.props.wrapStats}
                                   set_current_MainContent={this.props.set_current_MainContent}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

    export default SideBar;