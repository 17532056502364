import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call, func_privileges} from '../../../../../libs/utils';
// import './css/style.css';
import PubSub from 'pubsub-js';
import {confirm} from 'react-bootstrap-confirmation';




export default class TopicManagement extends Component {
    constructor(props) {
        let func = {"add": 138, 'del': 140, 'edit': 141, 'get': 142}        //用来显示页面按钮的显示相关的权限
        func = func_privileges(func)        //关于token请求的
        super(props);
        this.state = {
            "func": func,
             name: "一事联办",          //页面左上角，要显示的标题
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            column: [
                {
                    "name": "ID",
                    "selector": row => row.id,
                },
                {
                    "name": "联办名称",
                    "selector": row => row.ztmc
                },
                {
                    "name": "牵头部门",
                    "selector": row => row.qtbmmc
                },
                {
                    "name": "责任部门",
                    "selector": row => row.zrbmmc
                },
                {
                    "name": "联办内容",
                    "selector": row => row.ztnr
                },
                {
                    "name": "备注",
                    "selector": row => row.bz
                },
                {
                    "name": "操作",
                    "width": "100px",
                    "selector": row => {
                        return <div style={{"minWidth": "150px"}}>
                            { this.state.func.del ?<FontAwesomeIcon title='删除' icon="fa fa-trash"
                                             size='xl'
                                             style={{
                                                 'color': 'red',
                                                 "cursor": "pointer"
                                             }} onClick={e => {
                                this.del_sxlx(row.id)
                            }}/>:null}
                            { this.state.func.edit ?<FontAwesomeIcon title='编辑' icon="fa fa-pen-to-square"
                                             size='xl'
                                             style={{
                                                 'color': '#d38269',
                                                 "cursor": "pointer",
                                                 "marginLeft": '5px'
                                             }} onClick={e => {
                                this.edit_sxlx(e, row.id)
                            }}/>:null}
                        </div>
                    }
                },

            ],
            "search_rule": [
                {
                    "label_name": "联办名称",
                    "type": "text",
                    "id": "search_ztmc",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
            ],
            selectRows: {},
        }
    }

    componentDidMount() {
        this.get_all_sxlx()
    }

    add_zt = (e) => {
        let options = []
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/department:id,bmmc', 'GET').then(res => {
            res.DATA.forEach((val) => {
            options.push({"value": val.id, "label": val.bmmc})
        })
        PubSub.publish('Loding');
        this.setState({
            "modal_show": true,
            "modal_title": "新增联办",
            "modal_rules": [
                {
                    "label_name": "联办名称",
                    "type": "text",
                    "id": "ztmc",
                    "value": "",
                    "placeholder": "请输入联办名称",
                    "must": true,
                    "nullable": false,
                },
                {
                    "label_name": "牵头部门",
                    "type": "select",       //设置输入框类型：input、select选择
                    "id": "qtbmid",
                    "value":[],
                    "optionData":options,
                    // "value": [{"value": "1", "label": "aaa"}],      //多选是，是数组对象，，单选时是list对象
                    // "optionData": [{"value": "1", "label": "23"}, {"value": "2", "label": "444"},{"value": "3", "label": "vgdd"}],      //选择下拉框的内容
                    "multi": true,      //设置是否可以多选
                    "field": 'value',   //只拿结果的 value字段
                    "must": true,       //设置是否为必选项
                    "nullable": false,  //当为必填项时，将这个值设置成  否
                },
                {
                    "label_name": "责任部门",
                    "type": "select",
                    "id": "zrbmid",
                    "value": [],
                    "optionData": options,
                    "multi": true, 
                    "field":"value",
                    "must": false,
                    "nullable": true,
                },
                {
                    "label_name": "联办内容",
                    "type": "textarea",
                    "id": "ztnr",
                    "value": [],
                    "multi": true, 
                    "field":"value",
                    "must": false,
                    "nullable": true,
                },
                {
                    "label_name": "备注",
                    "type": "text",
                    "id": "bz",
                    "value": "",
                    "must": false,
                    "nullable": true,
                },
            ],
            "modal_submit_title": "提交",
            "modal_submit_callback": (e, data) => {
                // console.log(data)
                data['qtbmid'] = data['qtbmid'].join(',');
                data['zrbmid'] = data['zrbmid'].join(',');
                http_call('/jzzwdt/topic', 'POST', data).then(res => {
                    let alert_info;
                    if (res.RTN_CODE === '01') {
                        alert_info = {
                            "title": "提示",
                            "content": res.RTN_MSG,
                            "type": "success",
                            "position": "top-right",
                            "dismiss": 1000,
                        }
                        this.setState({"modal_show": false})
                        this.get_all_sxlx()
                    } else {
                        alert_info = {
                            "title": "提示",
                            "content": res.RTN_MSG,
                            "type": "error",
                            "position": "top-right",
                            "dismiss": 1000,
                        }
                    }
                    PubSub.publish('AlertNotify', alert_info);
                })
            },
        });
    })
    }

    edit_sxlx= (e, id) => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/topic', 'GET', {'id': id}).then(res => {
            let options = []
            let option = {"value": 0, "label": "请选择"}
            options.push(option)
            // console.log(res.DATA[0].qtbm);
            http_call('/jzzwdt/department:id,bmmc', 'GET').then(res_1 => {
                    res_1.DATA.forEach((val) => {
                        options.push({"value": val.id, "label": val.bmmc});
                        if (val.id === res.DATA[0].qtbmid) {
                            option = {"value": val.id, "label": val.bmmc};
                        }

                        let qtbm = [{"value": "",  "label": '请选择'}];
                        if (res.DATA[0].qtbm !== "") {
                            qtbm = res.DATA[0].qtbmid.split(',').map(val_1 => {
                                let label_mc = ''
                                options.forEach((selectmc)=>{
                                    if (selectmc.value === val_1) {
                                        label_mc = selectmc.label
                                    }
                                })
                                return {"value": val_1, "label": label_mc}
                            })
                        }

                        
                        let zrbm = [{"value": "",  "label": '请选择'}];
                        if (res.DATA[0].zrbm !== "") {
                            zrbm = res.DATA[0].zrbmid.split(',').map(val_2 => {
                                let label_mc = ''
                                options.forEach((selectmc)=>{
                                    if (selectmc.value === val_2) {
                                        label_mc = selectmc.label
                                    }
                                })
                                return {"value": val_2, "label": label_mc}
                            })
                        }
                        PubSub.publish('Loding');
                this.setState({
                    "modal_show": true,
                    "modal_title": "修改事项类型",
                    "modal_rules": [
                        {
                            "label_name": "id",
                            "type": "hidden",
                            "id": "id",
                            "value": id,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "联办名称",
                            "type": "text",
                            "id": "ztmc",
                            "value": res.DATA[0].ztmc,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "牵头部门",
                            "type": "select",
                            "id": "qtbmid",
                            // "value": [{"value":res.DATA[0].qtbmid , "label": res.DATA[0].qtbmmc}],
                            "value": qtbm,
                            "optionData": options,
                            "multi": true,
                            "field": 'value',
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "责任部门",
                            "type": "select",
                            "id": "zrbmid",
                            "value": zrbm,
                            // "value": [{"value":res.DATA[0].zrbmid , "label": res.DATA[0].zrbmmc}],
                            "optionData": options,
                            "field": 'value',
                            "multi": true,      //设置是否可以多选
                            "must": false,
                            "nullable": true,
                        },
                        {
                            "label_name": "联办内容",
                            "type": "textarea",
                            "id": "ztnr",
                            "value": res.DATA[0].ztnr,
                            "field": 'value',
                            "multi": true,      //设置是否可以多选
                            "must": false,
                            "nullable": true,
                        },
                        {
                            "label_name": "备注",
                            "type": "textarea",
                            "id": "bz",
                            "value": res.DATA[0].bz,
                            "must": false,
                            "nullable": true,
                        },
                    ],
                    "modal_submit_title": "提交",
                    "modal_submit_callback": (e, data) => {
                        data['qtbmid'] = data['qtbmid'].join(',');
                        data['zrbmid'] = data['zrbmid'].join(',');
                        http_call('/jzzwdt/topic/edit', 'POST', data).then(res => {
                            let alert_info;
                            if (res.RTN_CODE === '01') {
                                alert_info = {
                                    "title": "提示",
                                    "content": res.RTN_MSG,
                                    "type": "success",
                                    "position": "top-right",
                                    "dismiss": 1000,
                                }
                                this.setState({"modal_show": false})
                                this.get_all_sxlx()
                            } else {
                                alert_info = {
                                    "title": "提示",
                                    "content": res.RTN_MSG,
                                    "type": "error",
                                    "position": "top-right",
                                    "dismiss": 1000,
                                }
                            }
                            PubSub.publish('AlertNotify', alert_info);
                        })
                    },
                });
            })
            })
        })
    }

    del_sxlx= async (id) => {
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
            {
                "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
                "okText": "确认",
                okButtonStyle: 'warning',
                "cancelText": "取消"
            });
        if (result) {
            http_call('/jzzwdt/topic/delete', 'POST', {'id': id}).then(res => {
                let alert_info;
                if (res.RTN_CODE === '01') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    this.setState({"modal_show": false})
                    this.get_all_sxlx()
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }

    get_all_sxlx = () => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/topic', 'GET').then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    inquiry_sxlx = (data) => {
        // console.log(data);
        let DATA = {}
        PubSub.publish('Loding', true);
        Object.keys(data).forEach((key)=>{
            DATA[key.replace('search_', '')] = data[key];
        })
        // console.log(DATA)
        // DATA = data == {}  ?  {"ztmc": data.search_mc} : {}
        http_call('/jzzwdt/topic', 'GET', DATA).then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    render() {
        return (
            <div className='page-inner'>
                <div className="page-header">
                    <h4 className="page-title">{this.state.name}</h4>
                    <ul className="breadcrumbs">
                        <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>{this.state.name}</span>
                        </li>
                    </ul>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                { this.state.func.add ?<button className='btn btn-primary btn-xs'
                                        onClick={e => {
                                            this.add_zt(e);
                                        }
                                        }>新增联办
                                </button>:null}
                                <TableSearchFields rule={this.state.search_rule} onSearch={(data) => {this.inquiry_sxlx(data)}}/>
                            </div>
                            <DataTable
                                noDataComponent=<h1
                                style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>
                            columns={this.state.column}
                            data={this.state.tableData}
                            selectableRows
                            progressPending={this.state.pending}
                            pagination
                            paginationComponentOptions = {{
                                rowsPerPageText:'每页行数:',
                                rangeSeparatorText: '/',
                                selectAllRowsItem: true,
                                selectAllRowsItemText: '全部'
                            }}
                            fixedHeader
                            fixedHeaderScrollHeight={"100vh"}
                            theme=""
                            onSelectedRowsChange={(rows) => {
                            this.setState({"selectRows": rows})
                        }}
                            />
                        </div>
                    </div>
                </div>
                {this.state.modal_show ?
                    <DynamicModal show={this.state.modal_show}
                                  rules={this.state.modal_rules}
                                  title={this.state.modal_title}
                                  submit_title={this.state.modal_submit_title}
                                  close_callback={e => {
                                      this.setState({"modal_show": false})
                                  }}
                                  submit_callback={this.state.modal_submit_callback}
                    />
                    : null
                }
            </div>
        );
    }
}
