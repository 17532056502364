import React from 'react';
import './css/style.css';
import config from '../../config.js';


class Footer extends React.Component{
    render(){
        return <footer className="footer">
            <div className="container-fluid">
                <nav className="pull-left">
                    <ul className="nav">
                        <li className="nav-item">
                            <a className="nav-link" href="javascript:void(0)">
                                {config.footerText || 'Longmiaohao'}
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className="copyright ml-auto">
                    {config.copyright || 'Copyright © Longmiaohao'}
                </div>
            </div>
        </footer>
    }
}

export default Footer;