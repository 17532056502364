import React from "react";
import Notify from '../../../components/Notify/Notify';
import Profile from '../../../components/Profile';
// import SearchInput from '../../../components/SearchInput/SearchInput';


class Nav extends React.Component{
    // constructor(props){
    //     super(props);
    // }
    render() {
        return (
            <nav className="navbar navbar-header navbar-expand-lg" data-background-color={this.props.theme.nav}>
                <div className="container-fluid">
                    <div className="collapse" id="search-nav">
                        {/* <SearchInput/> */}
                    </div>
                    <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
                        <Notify/>
                        <Profile/>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default Nav;