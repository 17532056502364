import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call, func_privileges} from '../../../../../libs/utils';
import './css/style.css';
import PubSub from 'pubsub-js';
import {confirm} from 'react-bootstrap-confirmation';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import axios from 'axios';
import config from '../../../../../config.js';


export default class LobbiesManagement extends Component {
    constructor(props) {
        let func = {"add": 113, 'del': 115, 'edit': 114, 'get': 112, 'tpsc': 136}
        func = func_privileges(func)
        super(props);
        this.state = {
            "func": func,
            name: "大厅管理",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            column: [
                {
                    "name": "ID",
                    "selector": row => row.id,
                },
                {
                    "name": "大厅名称",
                    "selector": row => row.dtmc
                },
                {
                    "name": "大厅地址",
                    "selector": row => row.dtdz
                },
                {
                    "name": "大厅坐标",
                    "selector": row => row.dtzb
                },
                {
                    "name": "联系电话",
                    "selector": row => row.dtlxdh
                },
                {
                    "name": "办公时间",
                    "selector": row => row.bgsj
                },
                {
                    "name": "大厅简介",
                    "selector": row => row.dtjj,
                    maxWidth: '600px',
                    whiteSpace: 'break-spaces',
                },
                {
                    "name": "操作",
                    "width": "100px",
                    "selector": row => {
                        return <div style={{"minWidth": "150px"}}>
                            { this.state.func.del ?<FontAwesomeIcon title='删除' icon="fa fa-trash"
                                             size='xl'
                                             style={{
                                                 'color': 'red',
                                                 "cursor": "pointer"
                                             }} onClick={e => {
                                this.del_dt(row.id)
                            }}/>:null}
                            { this.state.func.edit ?<FontAwesomeIcon title='编辑' icon="fa fa-pen-to-square"
                                             size='xl'
                                             style={{
                                                 'color': '#d38269',
                                                 "cursor": "pointer",
                                                 "marginLeft": '5px'
                                             }} onClick={e => {
                                this.edit_dt(e, row.id)
                            }}/>:null}
                            { this.state.func.tpsc ?<FontAwesomeIcon title='照片' icon="fa fa-image"
                                             size='xl'
                                             style={{
                                                 'color': '#ccc',
                                                 "cursor": "pointer",
                                                 "marginLeft": '5px'
                                             }} onClick={e => {
                                this.get_picture(row.id)
                            }}/>:null}
                        </div>
                    }
                },

            ],
            "search_rule": [
                {
                    "label_name": "大厅名称",
                    "type": "text",
                    "id": "search_dtmc",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                {
                    "label_name": "大厅地址",
                    "type": "text",
                    "id": "search_dtdz",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                {
                    "label_name": "联系电话",
                    "type": "text",
                    "id": "search_lxdh",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
            ],
            selectRows: {},
            previewOpen: false,
            previewImage: '',
            previewTitle: '',
            dtid: '',
            fileList: [],
        }
    }

    componentDidMount() {
        this.get_all_dt()
    }

    add_dt = (e) => {
        PubSub.publish('Loding', true);
        let options = [{"value": 0, "label": "无上级区划"}]
        http_call('/jzzwdt/area:id,qymc', 'GET').then(res_1 => {
            res_1.DATA.forEach((val) => {
                options.push({"value": val.id, "label": val.qymc})
            })
            PubSub.publish('Loding');
            this.setState({
                "modal_show": true,
                "modal_title": "新增大厅",
                "modal_rules": [
                    {
                        "label_name": "所属区域",
                        "type": "select",
                        "id": "ssqyid",
                        "value": {"value": 0, "label": "无"},
                        "must": true,
                        "nullable": false,
                        "multi": false,
                        "placeholder": "请选择区划",
                        "field": "value",
                        "optionData": options,
                    },
                    {
                        "label_name": "大厅名称",
                        "type": "text",
                        "id": "dtmc",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "大厅地址",
                        "type": "text",
                        "id": "dtdz",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": <span>大厅坐标(<a
                            href='https://lbs.amap.com/tools/picker' target='_blank' rel="noopener noreferrer">坐标拾取器</a>)</span>,
                        "type": "text",
                        "id": "dtzb",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "大厅联系电话",
                        "type": "text",
                        "id": "dtlxdh",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "办公时间",
                        "type": "text",
                        "id": "bgsj",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "大厅简介",
                        "type": "richtext",
                        "id": "dtjj",
                        "value": "",
                        "must": true,
                        "nullable": true,
                    },
                ],
                "modal_submit_title": "提交",
                "modal_submit_callback": (e, data) => {
                    http_call('/jzzwdt/lobbies', 'POST', data).then(res => {
                        let alert_info;
                        if (res.RTN_CODE === '01') {
                            alert_info = {
                                "title": "提示",
                                "content": res.RTN_MSG,
                                "type": "success",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                            this.setState({"modal_show": false})
                            this.get_all_dt()
                        } else {
                            alert_info = {
                                "title": "提示",
                                "content": res.RTN_MSG,
                                "type": "error",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                        }
                        PubSub.publish('AlertNotify', alert_info);
                    })
                },
            });
        })
    }

    edit_dt =  (e, id) => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/lobbies', 'GET', {'id': id}).then(async res => {
            let options = [{"value": 0, "label": "无上级区划"}]
            let option = {"value": 0, "label": "无上级区划"}
            http_call('/jzzwdt/area:id,qymc', 'GET').then(res_1 => {
                res_1.DATA.forEach((val) => {
                    options.push({"value": val.id, "label": val.qymc})
                    if (val.id === res.DATA[0].ssqyid) {
                        option = {"value": val.id, "label": val.qymc}
                    }
                })
                PubSub.publish('Loding');
                this.setState({
                    "modal_show": true,
                    "modal_title": "修改大厅",
                    "modal_rules": [
                        {
                            "label_name": "大厅id",
                            "type": "hidden",
                            "id": "id",
                            "value": id,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "所属区域",
                            "type": "select",
                            "id": "ssqyid",
                            "value": option,
                            "must": true,
                            "nullable": false,
                            "multi": false,
                            "placeholder": "请选择区划",
                            "field": "value",
                            "optionData": options,
                        },
                        {
                            "label_name": "大厅名称",
                            "type": "text",
                            "id": "dtmc",
                            "value": res.DATA[0].dtmc,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "大厅地址",
                            "type": "text",
                            "id": "dtdz",
                            "value": res.DATA[0].dtdz,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": <span>大厅坐标(<a
                                href='https://lbs.amap.com/tools/picker' rel="noopener noreferrer" target='_blank'>坐标拾取器</a>)</span>,
                            "type": "text",
                            "id": "dtzb",
                            "value": res.DATA[0].dtzb,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "大厅联系电话",
                            "type": "text",
                            "id": "dtlxdh",
                            "value": res.DATA[0].dtlxdh,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "办公时间",
                            "type": "text",
                            "id": "bgsj",
                            "value": res.DATA[0].bgsj,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "大厅简介",
                            "type": "richtext",
                            "id": "dtjj",
                            "value": res.DATA[0].dtjj,
                            "must": true,
                            "nullable": true,
                        },
                    ],
                    "modal_submit_title": "提交",
                    "modal_submit_callback": (e, data) => {
                        http_call('/jzzwdt/lobbies/edit', 'POST', data).then(res => {
                            let alert_info;
                            if (res.RTN_CODE === '01') {
                                alert_info = {
                                    "title": "提示",
                                    "content": res.RTN_MSG,
                                    "type": "success",
                                    "position": "top-right",
                                    "dismiss": 1000,
                                }
                                this.setState({"modal_show": false})
                                this.get_all_dt()
                            } else {
                                alert_info = {
                                    "title": "提示",
                                    "content": res.RTN_MSG,
                                    "type": "error",
                                    "position": "top-right",
                                    "dismiss": 1000,
                                }
                            }
                            PubSub.publish('AlertNotify', alert_info);
                        })
                    },
                });
            })
        })
    }

    del_dt= async (id) => {
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
            {
                // "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
                "okText": "确认",
                okButtonStyle: 'warning',
                "cancelText": "取消"
            });
        if (result) {
            http_call('/jzzwdt/lobbies/delete', 'POST', {'id': id}).then(res => {
                let alert_info;
                if (res.RTN_CODE === '01') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    this.setState({"modal_show": false})
                    this.get_all_dt()
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }

    get_all_dt = () => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/lobbies', 'GET').then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    inquiry_dt = (data) => {
        let DATA = {}
        PubSub.publish('Loding', true);
        Object.keys(data).forEach((key)=>{
            DATA[key.replace('search_', '')] = data[key];
        })
        http_call('/jzzwdt/lobbies', 'GET', DATA).then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    get_picture = (id) => {
        PubSub.publish('Loding', true);
        this.setState({dtid: id})
        http_call('/jzzwdt/lobbies/photos', 'GET', {dtid: id}).then(res => {
            if (res.DATA.length){
                this.handleChange({
                    fileList: res.DATA[0].wjmc.split(',').map((src) => {
                        return {
                            uid: src.split('/')[src.split('/').length - 1].split('.')[0],
                            name: src.split('/')[src.split('/').length - 1],
                            status: 'done',
                            url: config.api_url + src,
                        }
                    })
                });
            }else {
                this.handleChange({fileList: []});
            }
        }).then(() => {
            PubSub.publish('Loding');
            this.setState({
                "modal_show": true,
                "modal_title": "大厅图片",
                "modal_rules": [
                    {
                        "label_name": "",
                        "type": "text",
                        "id": "",
                        "value": "",
                        "must": false,
                        "nullable": true,
                        "callback": () => {
                            const {fileList, previewOpen, previewTitle, previewImage} = this.state;
                            return <div>
                                <Upload
                                    // action="/jzzwdt/lobbies/add_photos"
                                    customRequest={this.customRequest}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleChange}
                                    onRemove={this.onRemove}
                                >
                                    {fileList.length >= 999 ? null : this.uploadButton}
                                </Upload>
                                <Modal open={previewOpen} title={previewTitle} footer={null}
                                       onCancel={this.handleCancel}>
                                    <img alt="example" style={{width: '100%'}} src={previewImage}/>
                                </Modal>
                            </div>
                        }
                    },
                ],
                "modal_submit_title": "",
                "modal_submit_callback": (e, data) => {
                },
            });
        })
    }

    get_tp = (id) =>{
        http_call('/jzzwdt/lobbies/photos', 'GET', {dtid: id}).then(res => {
            if (res.DATA.length){
                this.handleChange({
                    fileList: res.DATA[0].wjmc.split(',').map((src) => {
                        return {
                            uid: src.split('/')[src.split('/').length - 1].split('.')[0],
                            name: src.split('/')[src.split('/').length - 1],
                            status: 'done',
                            url: config.api_url + src,
                        }
                    })
                });
            }
        })
    }

    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    customRequest = (option: any) => {
        const formData = new FormData();
        formData.append("file", option.file);
        formData.append("dtid", this.state.dtid);
        axios.post(config.api_url+'/jzzwdt/lobbies/add_photos', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': window.localStorage.getItem('accessToken')
            }
        }).then(response => {
            let res = response.DATA;
            this.get_tp(this.state.dtid);
            let alert_info;
            if (res.RTN_CODE === '01') {
                alert_info = {
                    "title": "提示",
                    "content": res.RTN_MSG,
                    "type": "success",
                    "position": "top-right",
                    "dismiss": 1000,
                }
                this.setState({"modal_show": false})
                this.get_all_dt()
            } else {
                alert_info = {
                    "title": "提示",
                    "content": res.RTN_MSG,
                    "type": "error",
                    "position": "top-right",
                    "dismiss": 1000,
                }
            }
            PubSub.publish('AlertNotify', alert_info);
        }).catch(error => {
            console.log(error);
        });
    }

    handleCancel = () => this.setState({previewOpen: false});

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChange = ({fileList: newFileList}) => {
        this.setState({fileList: newFileList})
    };

    onRemove = (file) => {
        http_call('/jzzwdt/lobbies/del_photos', 'POST', {wjmc: file.name}).then(res => {
            let alert_info;
            if (res.RTN_CODE === '01') {
                alert_info = {
                    "title": "提示",
                    "content": res.RTN_MSG,
                    "type": "success",
                    "position": "top-right",
                    "dismiss": 1000,
                }
            } else {
                alert_info = {
                    "title": "提示",
                    "content": res.RTN_MSG,
                    "type": "error",
                    "position": "top-right",
                    "dismiss": 1000,
                }
                this.get_tp(this.state.dtid);
            }
            PubSub.publish('AlertNotify', alert_info);
        })
    }

    uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>图片上传</div>
        </div>
    );

    render() {
        return (
            <div className='page-inner'>
                <div className="page-header">
                    <h4 className="page-title">{this.state.name}</h4>
                    <ul className="breadcrumbs">
                        <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>{this.state.name}</span>
                        </li>
                    </ul>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                { this.state.func.add ?<button className='btn btn-primary btn-xs'
                                        onClick={e => {
                                            this.add_dt(e);
                                        }
                                        }>新增大厅
                                </button>:null}
                                <TableSearchFields rule={this.state.search_rule} onSearch={(data) => {this.inquiry_dt(data)}}/>
                            </div>
                            <DataTable
                                noDataComponent=<h1
                                style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>
                            columns={this.state.column}
                            data={this.state.tableData}
                            selectableRows
                            progressPending={this.state.pending}
                            pagination
                            paginationComponentOptions = {{
                                rowsPerPageText:'每页行数:',
                                rangeSeparatorText: '/',
                                selectAllRowsItem: true,
                                selectAllRowsItemText: '全部'
                            }}
                            fixedHeader
                            fixedHeaderScrollHeight={"100vh"}
                            theme=""
                            onSelectedRowsChange={(rows) => {
                            this.setState({"selectRows": rows})
                        }}
                            />
                        </div>
                    </div>
                </div>
                {this.state.modal_show ?
                    <DynamicModal show={this.state.modal_show}
                                  rules={this.state.modal_rules}
                                  title={this.state.modal_title}
                                  submit_title={this.state.modal_submit_title}
                                  close_callback={e => {
                                      this.setState({"modal_show": false})
                                  }}
                                  submit_callback={this.state.modal_submit_callback}
                    />
                    : null
                }
            </div>
        );
    }
}
