import React from "react";
import $ from 'jquery';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {api_call} from '../../../../libs/utils.js';


// 菜单参数：{
//             "menus": [
//                 {"name": "Dashboard", "ico": "fas fa-home|spin", "children": []},
//                 {"name": "系统设置", "ico": "fa-solid fa-gear|spin", "children": [
//                     {"name": "系统用户管理", "ico": "fa-solid fa-users", "children": []}
//                 ]},
//                 {"name": "menus", "ico": "", "children": [
//                     {"name": "menus1", "ico": "", "children": [
//                         {"name": "menus1-1", "ico": "", "children": [
//                             {"name": "menus1-2-1", "ico": "", "children": []},
//                         ]},
//                         {"name": "menus1-2", "ico": "", "children": []},
//                     ]},
//                     {"name": "menus2", "ico": "", "children": [
//                         {"name": "menus2-1", "ico": "", "children": []}
//                     ]}
//                 ]
//                 }
//             ]
//         }
class Menus extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            "menus": [
                // {"name": "Dashboard", "zh": "Dashboard", "ico": "fas fa-home", "children": []},
                // {"name": "settings", "zh": "系统设置","ico": "fa-solid fa-gear|fa-spin", "children": [
                //     {"name": "SystemUsers", 'zh': "系统用户管理", "ico": "fa-solid fa-users", "children": []},
                //     {"name": "SystemMenus", "zh": "系统菜单管理", "ico": "fa-solid fa-bars", "children": []}
                // ]}
            ]
        }
    }

    set_menus = (res)=>{
        // 设置展开路径
        let url_path_component = window.location.pathname.split('/')[1]
        for(let i = 0; i < res.DATA.length; i++){
            if(res.DATA[i].name === url_path_component){
                res.DATA[i]['active'] = true  // 一级
                this.setState({pre_click_menu: 'menu' + url_path_component});
                break;
            }
            else if (res.DATA[i].children.length > 0){ // 二级
                for(let j =0; j < res.DATA[i].children.length; j++){
                    if (res.DATA[i].children[j].name === url_path_component){
                        res.DATA[i]['active'] = true
                        res.DATA[i].children[j]['active'] = true
                    }
                }
            }
        }
        this.setState({"menus": res.DATA})
        let menus = [];
        this.get_children(res.DATA, menus)
        window.localStorage.setItem('grant_menus', JSON.stringify(menus))
    }

    // 获取所有授权菜单
    get_children = (child, menus) => {
        if (child.length === 0) return
        child.forEach(item => {
            if (item.name !== "") menus.push(item.name)
            if (item.children.length > 0)
                this.get_children(item.children, menus)
        })
    }

    componentDidMount() {
        api_call('/systemdefault/get_system_menu', 'GET', this.set_menus)
    }

    set_current_MainContent = (e, MainContentComponent) => {
      e.stopPropagation();
      this.setState({"current_MainContent": MainContentComponent});
      this.props.set_current_MainContent(MainContentComponent);
      if (this.state.pre_click_submenu !== '')
        $("#" + this.state.pre_click_submenu).removeClass('active');
      $("#" + MainContentComponent).addClass('active');
      this.setState({"pre_click_submenu": MainContentComponent});
    }

    dropdown = (e, id) => {
        e.stopPropagation();
        if (this.state.pre_click_menu !== ''){
            if (typeof(this.state.pre_click_menu) === 'object'){
                this.state.pre_click_menu.removeClass('active')
            }
            else{
                $($("#" + this.state.pre_click_menu).parents()[0]).removeClass('active');
            }
        }
        if (id === ''){     // 兼容没有子菜单的菜单
            $($(e.target).parents()[0]).addClass('active')
            this.setState({pre_click_menu: $($(e.target).parents()[0])});
            if (this.state.pre_click_submenu !== '')
            $("#" + this.state.pre_click_submenu).removeClass('active');
            return;
        }
        $($("#" + id).parents()[0]).addClass('active')
        this.setState({pre_click_menu: id});
        // 箭头反转
        if ($($($("#" + id)[0]).siblings()[0]).children('.caret').hasClass('caret_trans')){     //关闭
            $($($("#" + id)[0]).siblings()[0]).children('.caret').removeClass('caret_trans');
            $($("#" + id)[0]).slideToggle();
        }
        else{       // 拉开
            $($($("#" + id)[0]).siblings()[0]).children('.caret').addClass('caret_trans')
            $($("#" + id)[0]).slideToggle();
        }
        if (!this.state[id]) {
            $($("#" + id)[0]).addClass('show');
        }
        else{
            $($("#" + id)[0]).removeClass('show');
        }
        this.setState({[id]: !this.state[id]});
        // this.props.wrapStats(this.props.wrapClassName);
    }

    randomString = (length) => {
        let str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let result = '';
        for (var i = length; i > 0; --i)
            result += str[Math.floor(Math.random() * str.length)];
        return result;
    }
    generate_menu = (items) => {
        let menus = [];
        let ico_style = [];
        items.forEach((item, index) => {
            if (item.name === ''){  //随机生成父ID
                item.name = this.randomString(6)
            }
            if (item.ico === '')
                ico_style = ['', '']
            else if (item.ico.split('|').length === 1){
                ico_style = [item.ico, '']
            }
            else
                ico_style = item.ico.split('|') // 0 class 1 animate
            if (item.children.length === 0){
                menus.push(
                    <li key={item.name} className='nav-item' style={{"cursor": "pointer"}} id={item.name}
                                onClick={
                                 e=>{this.dropdown(e, '');
                                 this.set_current_MainContent(e, item.name)}
                                }>
                                <a href={item.name} onClick={(e)=>{e.preventDefault()}} className="collapsed">
                                    {ico_style[0] !== '' ? <FontAwesomeIcon icon={ico_style[0]} className={ico_style[1]}/> : ''}
                                     <p>{item.zh}</p>
                                </a>
                            </li>
                )
            }
            else{
                menus.push(<li key={item.name} className="nav-item" style={{"cursor": "pointer"}}
                    onClick={(e) => this.dropdown(e, 'menu' + item.name)}>
                        <a href={item.name} onClick={(e)=>{e.preventDefault()}}>
                            {ico_style[0] !== '' ? <FontAwesomeIcon icon={ico_style[0]} className={ico_style[1]}/> : ''}
                            <p>{item.zh}</p>
                            <span className="caret"></span>
                        </a>
                    <div className="collapse subnav" id={'menu' + item.name}>
                        <ul className="nav">
                            {this.generate_menu(item.children)}
                        </ul>
                    </div>
                    </li>
                )
            }
        })
        return menus;

    }
    render() {
        return(
            <ul className="nav nav-primary">
                {
                    this.generate_menu(this.state.menus)
                }
            </ul>
        )
    }
}

export default Menus;