import React, {Component} from 'react';
import DataTable from 'react-data-table-component';
import DynamicModal from '../../../../../components/DynamicModal/DynamicModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TableSearchFields from '../../../../../components/TableSearchFields/TableSearchFields';
import {http_call, func_privileges} from '../../../../../libs/utils';
import './css/style.css';
import PubSub from 'pubsub-js';
import {confirm} from 'react-bootstrap-confirmation';




export default class Items extends Component {
    constructor(props) {
        let func = {"add": 150, 'del': 152, 'edit': 151, 'get': 149}
        func = func_privileges(func)
        super(props);
        this.state = {
            "func": func,
            name: "事项管理",
            "modal_show": false,
            "modal_title": "",
            "modal_rules": [],
            "modal_submit_title": "",
            "modal_submit_callback": "",
            column: [
                {
                    "name": "ID",
                    "selector": row => row.id,
                },
                {
                    "name": "实施部门",
                    "selector": row => row.ssbmmc
                },
                {
                    "name": "事项名称",
                    "selector": row => row.sxmc
                },
                {
                    "name": "备注",
                    "selector": row => row.bz
                },
                {
                    "name": "操作",
                    "width": "100px",
                    "selector": row => {
                        return <div style={{"minWidth": "150px"}}>
                            { this.state.func.del ?<FontAwesomeIcon title='删除' icon="fa fa-trash"
                                             size='xl'
                                             style={{
                                                 'color': 'red',
                                                 "cursor": "pointer"
                                             }} onClick={e => {
                                this.del_sx(row.id)
                            }}/>:null}
                            { this.state.func.edit ?<FontAwesomeIcon title='编辑' icon="fa fa-pen-to-square"
                                             size='xl'
                                             style={{
                                                 'color': '#d38269',
                                                 "cursor": "pointer",
                                                 "marginLeft": '5px'
                                             }} onClick={e => {
                                this.edit_sx(e, row.id)
                            }}/>:null}
                        </div>
                    }
                },

            ],
            "search_rule": [
                {
                    "label_name": "实施部门",
                    "type": "text",
                    "id": "search_ssbm",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
                {
                    "label_name": "事项名称",
                    "type": "text",
                    "id": "search_sxmc",
                    "value": "",
                    "must": false,
                    "nullable": false,
                },
            ],
            selectRows: {},
        }
    }

    componentDidMount() {
        this.get_all_sx()
    }

    add_sx = (e) => {
        let options = []
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/department:id,bmmc', 'GET').then(res => {
            res.DATA.forEach((val) => {
                options.push({"value": val.id, "label": val.bmmc})
            })
        PubSub.publish('Loding');
            this.setState({
                "modal_show": true,
                "modal_title": "新增事项",
                "modal_rules": [
                    {
                        "label_name": "事项名称",
                        "type": "text",
                        "id": "sxmc",
                        "value": "",
                        "must": true,
                        "nullable": false,
                    },
                    {
                        "label_name": "实施部门",
                        "type": "select",
                        "id": "ssbmid",
                        "value": {"value": 0, "label": "请选择"},
                        "must": true,
                        "nullable": false,
                        "multi": false,
                        "placeholder": "请选择实施部门",
                        "field": "value",
                        "optionData": options,
                    },
                    {
                        "label_name": "备注",
                        "type": "textarea",
                        "id": "bz",
                        "value": "",
                        "must": false,
                        "nullable": true,
                    },
                ],
                "modal_submit_title": "提交",
                "modal_submit_callback": (e, data) => {
                    // data['xscj'] = data['xscj'].join(',');
                    http_call('/jzzwdt/nodifferenceitems', 'POST', data).then(res => {
                        let alert_info;
                        if (res.RTN_CODE === '01') {
                            alert_info = {
                                "title": "提示",
                                "content": res.RTN_MSG,
                                "type": "success",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                            this.setState({"modal_show": false})
                            this.get_all_sx()
                        } else {
                            alert_info = {
                                "title": "提示",
                                "content": res.RTN_MSG,
                                "type": "error",
                                "position": "top-right",
                                "dismiss": 1000,
                            }
                        }
                        PubSub.publish('AlertNotify', alert_info);
                    })
                },
            });
        })
    }

    edit_sx= (e, id) => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/nodifferenceitems', 'GET', {'id': id}).then(res => {
            let options = []
            let option = {"value": 0, "label": "请选择"}
            options.push(option)
            http_call('/jzzwdt/department:id,bmmc', 'GET').then(res_1 => {
                res_1.DATA.forEach((val) => {
                    options.push({"value": val.id, "label": val.bmmc});
                    if (val.id === res.DATA[0].ssbmid) {
                        option = {"value": val.id, "label": val.bmmc};
                    }
                })
                PubSub.publish('Loding');
                this.setState({
                    "modal_show": true,
                    "modal_title": "修改事项",
                    "modal_rules": [
                        {
                            "label_name": "事项id",
                            "type": "hidden",
                            "id": "id",
                            "value": id,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "事项名称",
                            "type": "text",
                            "id": "sxmc",
                            "value": res.DATA[0].sxmc,
                            "must": true,
                            "nullable": false,
                        },
                        {
                            "label_name": "实施部门",
                            "type": "select",
                            "id": "ssbmid",
                            "value": option,
                            "must": true,
                            "nullable": false,
                            "multi": false,
                            "placeholder": "请选择实施部门",
                            "field": "value",
                            "optionData": options,
                        },
                        {
                            "label_name": "备注",
                            "type": "textarea",
                            "id": "bz",
                            "value": res.DATA[0].bz,
                            "must": false,
                            "nullable": true,
                        },
                    ],
                    "modal_submit_title": "提交",
                    "modal_submit_callback": (e, data) => {
                        http_call('/jzzwdt/nodifferenceitems/edit', 'POST', data).then(res => {
                            let alert_info;
                            if (res.RTN_CODE === '01') {
                                alert_info = {
                                    "title": "提示",
                                    "content": res.RTN_MSG,
                                    "type": "success",
                                    "position": "top-right",
                                    "dismiss": 1000,
                                }
                                this.setState({"modal_show": false})
                                this.get_all_sx()
                            } else {
                                alert_info = {
                                    "title": "提示",
                                    "content": res.RTN_MSG,
                                    "type": "error",
                                    "position": "top-right",
                                    "dismiss": 1000,
                                }
                            }
                            PubSub.publish('AlertNotify', alert_info);
                        })
                    },
                });
            })
        })
    }

    del_sx= async (id) => {
        const result = await confirm(<h2 className='text-center text-danger'>确认删除?</h2>,
            {
                "title": <div><h4 className='text-center text-warning'>警告</h4></div>,
                "okText": "确认",
                okButtonStyle: 'warning',
                "cancelText": "取消"
            });
        if (result) {
            http_call('/jzzwdt/nodifferenceitems/delete', 'POST', {'id': id}).then(res => {
                let alert_info;
                if (res.RTN_CODE === '01') {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "success",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                    this.setState({"modal_show": false})
                    this.get_all_sx()
                } else {
                    alert_info = {
                        "title": "提示",
                        "content": res.RTN_MSG,
                        "type": "error",
                        "position": "top-right",
                        "dismiss": 1000,
                    }
                }
                PubSub.publish('AlertNotify', alert_info);
            })
        }
    }

    get_all_sx = () => {
        PubSub.publish('Loding', true);
        http_call('/jzzwdt/nodifferenceitems', 'GET').then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    inquiry_sx = (data) => {
        let DATA = {}
        PubSub.publish('Loding', true);
        Object.keys(data).forEach((key)=>{
            DATA[key.replace('search_', '')] = data[key];
        })
        http_call('/jzzwdt/nodifferenceitems', 'GET', DATA).then(res => {
            this.setState({"tableData": res.DATA})
            PubSub.publish('Loding');
        })
    }

    render() {
        return (
            <div className='page-inner'>
                <div className="page-header">
                    <h4 className="page-title">{this.state.name}</h4>
                    <ul className="breadcrumbs">
                        <li className="nav-home">
                            <span>
                                <FontAwesomeIcon icon="fa fa-home"/>
                            </span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                          <li className="nav-item">
                            <span>无差别服务</span>
                        </li>
                        <li className="separator">
                            <FontAwesomeIcon icon="fa-solid fa-greater-than"/>
                        </li>
                        <li className="nav-item">
                            <span>{this.state.name}</span>
                        </li>
                    </ul>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div style={{"marginLeft": '5px', "marginBottom": '5px'}}>
                                { this.state.func.add ?<button className='btn btn-primary btn-xs'
                                        onClick={e => {
                                            this.add_sx(e);
                                        }
                                        }>新增事项
                                </button>:null }
                                <TableSearchFields rule={this.state.search_rule} onSearch={(data) => {this.inquiry_sx(data)}}/>
                            </div>
                            <DataTable
                                noDataComponent=<h1
                                style={{"textAlign": "center", "marginBottom": '20px'}}>暂无数据</h1>
                            columns={this.state.column}
                            data={this.state.tableData}
                            selectableRows
                            progressPending={this.state.pending}
                            pagination
                            paginationComponentOptions = {{
                                rowsPerPageText:'每页行数:',
                                rangeSeparatorText: '/',
                                selectAllRowsItem: true,
                                selectAllRowsItemText: '全部'
                            }}
                            fixedHeader
                            fixedHeaderScrollHeight={"100vh"}
                            theme=""
                            onSelectedRowsChange={(rows) => {
                            this.setState({"selectRows": rows})
                        }}
                            />
                        </div>
                    </div>
                </div>
                {this.state.modal_show ?
                    <DynamicModal show={this.state.modal_show}
                                  rules={this.state.modal_rules}
                                  title={this.state.modal_title}
                                  submit_title={this.state.modal_submit_title}
                                  close_callback={e => {
                                      this.setState({"modal_show": false})
                                  }}
                                  submit_callback={this.state.modal_submit_callback}
                    />
                    : null
                }
            </div>
        );
    }
}
